import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetNewsModel, NewsModel } from 'src/app/core/models/news/news.model';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Ocorreu um erro:', JSON.stringify(error));
    return throwError('Algo deu errado. Tente novamente mais tarde.');
  }

  private host: string = environment.authHost;
  private refresh$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}

  createNews(data: NewsModel): Observable<NewsModel> {
    return this.http.post<NewsModel>(this.host + '/news', data).pipe(
      tap(() => {
        this.refresh$.next();
      }),
      catchError(err => {
        this.notificationService.showNotification(
          err.error.message,
          'OK',
          5000
        );
        return throwError(err);
      })
    );
  }

  updateNews(data: NewsModel, id: number): Observable<NewsModel> {
    return this.http.patch<NewsModel>(this.host + '/news/id/' + id, data).pipe(
      tap(() => {
        this.refresh$.next();
      }),
      catchError(err => {
        this.notificationService.showNotification(
          err.error.message,
          'OK',
          5000
        );
        return throwError(err);
      })
    );
  }

  deletId(id: string): Observable<NewsModel> {
    return this.http.delete<NewsModel>(this.host + '/news/id/' + id).pipe(
      tap(() => {
        this.refresh$.next();
      }),
      catchError(err => {
        this.notificationService.showNotification(
          err.error.message,
          'OK',
          5000
        );
        return throwError(err);
      })
    );
  }

  getNewsId(id: string): Observable<NewsModel> {
    return this.http.get<NewsModel>(this.host + '/news/id/' + id).pipe(
      tap(() => {
        this.refresh$.next();
      }),
      catchError(err => {
        this.notificationService.showNotification(
          err.error.message,
          'OK',
          5000
        );
        return throwError(err);
      })
    );
  }

  getAllNews(filters?: any): Observable<NewsModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<NewsModel[]>(this.host + '/news', { params: param })
      .pipe(
        tap(() => {
          this.refresh$.next();
        }),
        catchError(err => {
          this.notificationService.showNotification(
            err.error.message,
            'OK',
            5000
          );
          return throwError(err);
        })
      );
  }

  getReadNews(filters?: any): Observable<NewsModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<NewsModel[]>(this.host + '/readnews', { params: param })
      .pipe(
        tap(() => {
          this.refresh$.next();
        }),
        catchError(err => {
          this.notificationService.showNotification(
            err.error.message,
            'OK',
            5000
          );
          return throwError(err);
        })
      );

  }

  updateActivedNew(
    id: number,
    featured: boolean,
    beginDate: string,
    end_date: string
  ): Observable<NewsModel> {
    const updatedNoticia = {
      featured: featured,
      begin_date: beginDate,
      end_date: end_date,
    };
    return this.http.patch<any>(`${this.host}/news/id/${id}`, updatedNoticia);
  }

  getPagination(
    limit: number,
    offset: number,
    search: string
  ): Observable<NewsModel[]> {
    let link = '';
    if (search !== '') {
      link =
        '/news?offset=' + offset + '&limit=' + limit + '&partSearch=' + search;
    } else {
      link = '/news?offset=' + offset + '&limit=' + limit;
    }
    return this.http.get<NewsModel[]>(this.host + link).pipe(
      tap(() => {
        this.refresh$.next();
      })
    );
  }
}
