<div class="flex h-[100%] w-full overflow-auto" infiniteScroll [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="100" (scrolled)="onScroll()" [scrollWindow]="false">
  <table mat-table [dataSource]="dataSource" matSort class="h-full">
    <ng-container *ngFor="let header of tableHeader; let i = index">
      <ng-container *ngIf="alternativeTable(header)" [matColumnDef]="header">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
          {{ customHeaders[i] || header }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element[header] }}
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="flex w-5 py-2">
          <mat-slide-toggle [checked]="element.active" (change)="disableComponent(element)" name="enablecol"><label
              class="inline-block pl-[0.15rem] hover:cursor-pointer" for="flexSwitchCheckDefault">
              <span class="font-bold">{{
                element.active ? 'Ativo' : 'Inativo'
                }}</span>
            </label></mat-slide-toggle>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Data
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.date ? (element.date | date : 'dd/MM/yyyy') : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalHours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Total de Horas
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.totalHours ? (element.totalHours | mask : '00:00') : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Hora Inicial
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.startTime ? (element.startTime | mask : '00:00') : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Hora Final
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.endTime ? (element.endTime | mask : '00:00') : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="featured">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Destaque
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle class="pl-5" [checked]="element.featured" (change)="disableComponent(element)">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        E-mail
      </th>
      <td mat-cell *matCellDef="let element">
        <a class="hover:underline" [attr.href]="'mailto:' + element.email">
          {{ element.email ? element.email : '' }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Telefone
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.phone ? (element.phone | mask : '(00) 00000-0000') : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Domínio
      </th>
      <td mat-cell *matCellDef="let element">
        <a class="hover:underline" [attr.href]="'//' + element.domain">
          {{ element.domain ? element.domain : '' }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-th">
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="flex items-center">
          <div *ngIf="enableColorStatus && ticketStatus" class="mr-2 h-3 w-3 min-w-[12px] rounded-full"
            [style.backgroundColor]="getStatusColor(element.status)"></div>
          <div>{{ element.status }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="custom-th">Ações</th>
      <td mat-cell class="w-32" *matCellDef="let element">
        <div class="grid gap-2 py-2">
          <div class="flex justify-end gap-4">
            <div *ngIf="showEdit" [matTooltip]="editToolTip">
              <button class="cursor-pointer py-0" [disabled]="disableEditButton()" (click)="edit(element, $event)">
                <span class="text-[#3E62B3]">
                  <img class="mr-2 h-6 w-6" src="assets/icons/edit_icon.svg" />
                </span>
              </button>
            </div>

            <div *ngIf="showDelete">
              <button matTooltip="{{ deleteToolTip }}" [disabled]="disableDeleteButton()" class="cursor-pointer py-0"
                (click)="delete(element, $event)">
                <span class="text-gray-500">
                  <img class="mr-2 h-6 w-6" src="assets/icons/delet_icon.svg" />
                </span>
              </button>
            </div>

            <div *ngIf="activeShowMore">
              <button matTooltip="Ver mais" class="cursor-pointer py-0" (click)="showMore(element)">
                <span class="text-gray-500">
                  <img class="mr-2 h-6 w-6" src="assets/icons/icon_eye.svg" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Definição das colunas -->
    <tr mat-header-row [ngClass]="'header'" *matHeaderRowDef="tableHeader; sticky: true"></tr>
    <tr (click)="lineClickEvent(row)" mat-row *matRowDef="let row; columns: tableHeader"></tr>
  </table>

</div>
