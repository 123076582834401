import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ForcedAccessModel } from '../../models/forced-access/forced-access.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForcedAccessService {
  private _forcedAccess = new BehaviorSubject<ForcedAccessModel[]>([]);
  private host?: string;
  constructor(private _httpClient: HttpClient) {
    this.host = environment.authHost;
  }

  get forcedAccess$(): Observable<ForcedAccessModel[]> {
    return this._forcedAccess.asObservable();
  }

  getForcedAccessByUserId(userId: number): Observable<ForcedAccessModel[]> {
    return this._httpClient
      .get<ForcedAccessModel[]>(this.host + '/forcedaccess/userId/' + userId)
      .pipe(
        tap(forcedAccess => {
          this._forcedAccess.next(forcedAccess);
        })
      );
  }

  createManyForcedAccess(
    forcedAccess: ForcedAccessModel[]
  ): Observable<ForcedAccessModel[]> {
    return this._httpClient
      .post<ForcedAccessModel[]>(this.host + '/forcedaccess/many', forcedAccess)
      .pipe(
        tap(forcedAccess => {
          this._forcedAccess.next(forcedAccess);
        })
      );
  }

  deleteManyForcedAccess(userId: number): Observable<ForcedAccessModel[]> {
    return this._httpClient
      .delete<ForcedAccessModel[]>(this.host + '/forcedaccess/many/' + userId)
      .pipe(
        tap(forcedAccess => {
          this._forcedAccess.next(forcedAccess);
        })
      );
  }
}
