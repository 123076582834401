import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetProjectTimeRegisterListModel,
  GetSprintTimeRegisterModel,
  SprintTimeRegisterModel,
  getUserTimeRegister,
} from '../../models/project/SprintTimeRegister.model';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root',
})
export class TimeRegisterService {
  private host: string = environment.authHost;

  private sprintTimeRegister!: GetSprintTimeRegisterModel;
  private _sprintTimeRegister = new BehaviorSubject<GetSprintTimeRegisterModel>(
    this.sprintTimeRegister
  );
  private sprintTimeRegisters!: GetSprintTimeRegisterModel[];
  private _sprintTimeRegisters = new BehaviorSubject<
    GetSprintTimeRegisterModel[]
  >([]);

  private projectListTimeRegister!: GetProjectTimeRegisterListModel[];
  private _projectListTimeRegister = new BehaviorSubject<
    GetProjectTimeRegisterListModel[]
  >(this.projectListTimeRegister);

  private userTimeRegisters!: getUserTimeRegister;
  private _userTimeRegisters = new BehaviorSubject<getUserTimeRegister>(
    this.userTimeRegisters
  );

  constructor(
    private http: HttpClient,
    private _notificationService: NotificationService
  ) {}

  get sprintTimeRegister$(): Observable<GetSprintTimeRegisterModel> {
    return this._sprintTimeRegister.asObservable();
  }

  get sprintTimeRegisters$(): Observable<GetSprintTimeRegisterModel[]> {
    return this._sprintTimeRegisters.asObservable();
  }

  get projectListTimeRegister$(): Observable<
    GetProjectTimeRegisterListModel[]
  > {
    return this._projectListTimeRegister.asObservable();
  }

  get userTimeRegisters$(): Observable<getUserTimeRegister> {
    return this._userTimeRegisters.asObservable();
  }

  getSprintTimeRegisters(
    filters?: any
  ): Observable<GetSprintTimeRegisterModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this.http
      .get<GetSprintTimeRegisterModel[]>(this.host + '/project/time-register', {
        params: param,
      })
      .pipe(
        tap(sprintTimeRegisters => {
          this.sprintTimeRegisters = sprintTimeRegisters;
          this._sprintTimeRegisters.next(sprintTimeRegisters);
        })
      );
  }

  downloadAllProjectsExcel(name: string, projectId: number | string): void {
    this.http
      .get(this.host + '/export/projectTime/project/' + projectId, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro na solicitação HTTP:', error);
          this._notificationService.showNotification(
            'Relatório não possui dados!',
            'OK',
            5000
          );
          throw error;
        })
      )
      .subscribe(data => {
        this.downloadFile(data, name);
      });
  }

  downloadListExcel(id: number | string, name: string): void {
    this.http
      .get(this.host + '/export/projectTime/sprint/' + id, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro na solicitação HTTP:', error);
          this._notificationService.showNotification(
            'Relatório não possui dados!',
            'OK',
            5000
          );
          throw error;
        })
      )
      .subscribe(data => {
        this.downloadFile(data, name);
      });
  }

  downloadListUserExcel(
    id: number | string,
    userid: number,
    name: string
  ): void {
    this.http
      .get(this.host + '/export/projectTime/sprint/' + id + '/' + userid, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro na solicitação HTTP:', error);
          this._notificationService.showNotification(
            'Relatório não possui dados!',
            'OK',
            5000
          );
          throw error;
        })
      )
      .subscribe(data => {
        this.downloadFile(data, name);
      });
  }

  downloadUserDateExcel(userid: number, name: string, filters?: any): void {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    this.http
      .get(this.host + '/export/projectTime/userTime/' + userid, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: param,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro na solicitação HTTP:', error);
          this._notificationService.showNotification(
            'Relatório não possui dados!',
            'OK',
            5000
          );
          throw error;
        })
      )
      .subscribe(data => {
        this.downloadFile(data, name);
      });
  }

  downloadFile(data: any, nomeDoArquivo: string): void {
    const blob = new Blob([data.body], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = nomeDoArquivo;
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(a);
  }

  getSprintTimeRegisterById(
    id: number
  ): Observable<GetSprintTimeRegisterModel> {
    return this.http
      .get<GetSprintTimeRegisterModel>(
        this.host + '/project/time-register/' + id
      )
      .pipe(
        tap(sprintTimeRegister => {
          this._sprintTimeRegister.next(sprintTimeRegister);
        })
      );
  }

  getSprintTimeRegisterBySprintId(
    id: number,
    filters?: any
  ): Observable<GetSprintTimeRegisterModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this.http
      .get<GetSprintTimeRegisterModel[]>(
        this.host + '/project/time-register/sprint/' + id,
        {
          params: param,
        }
      )
      .pipe(
        tap(sprintTimeRegisters => {
          this.sprintTimeRegisters = sprintTimeRegisters;
          this._sprintTimeRegisters.next(sprintTimeRegisters);
        })
      );
  }

  createSprintTimeRegister(
    sprintTimeRegister: SprintTimeRegisterModel
  ): Observable<GetSprintTimeRegisterModel> {
    return this.http
      .post<GetSprintTimeRegisterModel>(
        this.host + '/project/time-register',
        sprintTimeRegister
      )
      .pipe(
        tap(sprintTimeRegister => {
          const sprintTimeRegisters = [
            ...this.sprintTimeRegisters,
            sprintTimeRegister,
          ];
          this.sprintTimeRegisters = sprintTimeRegisters;
          this._sprintTimeRegisters.next(sprintTimeRegisters);
        })
      );
  }

  updateSprintTimeRegister(
    id: Number,
    sprintTimeRegister: SprintTimeRegisterModel
  ): Observable<GetSprintTimeRegisterModel> {
    return this.http
      .patch<GetSprintTimeRegisterModel>(
        this.host + '/project/time-register/' + id,
        sprintTimeRegister
      )
      .pipe(
        tap(sprintTimeRegister => {
          const sprintTimeRegisters = this.sprintTimeRegisters.map(p =>
            p.id === id ? sprintTimeRegister : p
          );
          this.sprintTimeRegisters = sprintTimeRegisters;
          this._sprintTimeRegisters.next(sprintTimeRegisters);
        })
      );
  }

  deleteSprintTimeRegister(id: number): Observable<any> {
    return this.http.delete(this.host + '/project/time-register/' + id).pipe(
      tap(() => {
        const sprintTimeRegisters = this.sprintTimeRegisters.filter(
          p => p.id !== id
        );
        this.sprintTimeRegisters = sprintTimeRegisters;
        this._sprintTimeRegisters.next(sprintTimeRegisters);
      })
    );
  }

  getProjecttimeRegisterlist(
    id: number
  ): Observable<GetProjectTimeRegisterListModel[]> {
    return this.http
      .get<GetProjectTimeRegisterListModel[]>(
        this.host + '/project/time-register-list/project/' + id
      )
      .pipe(
        tap(projectListTimeRegister => {
          this._projectListTimeRegister.next(projectListTimeRegister);
        })
      );
  }

  getUserTimeRegisters(
    userId: number,
    filters?: any
  ): Observable<getUserTimeRegister> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<getUserTimeRegister>(
        this.host + '/project/time-register-list/user/' + userId,
        {
          params: param,
        }
      )
      .pipe(
        tap(userTimeRegisters => {
          this._userTimeRegisters.next(userTimeRegisters);
        })
      );
  }
}
