import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input() parentSearchQuery: string = '';
  @Output() parentSearchQueryChange = new EventEmitter<string>();

  onSearchQueryChange() {
    this.parentSearchQueryChange.emit(this.parentSearchQuery);
  }
}
