<div class="box_shadow flex h-28 min-w-[250px] p-5">
  <div class="flex w-[80%] items-center">
    <div class="flex w-full gap-6">
      <div class="icon-color">
        <img class="" src="assets/icons/icon-fact-check.svg" alt="" />
      </div>

      <div class="flex w-[90%] justify-between">
        <div
          *ngFor="let item of data; index as i"
          class="flex cursor-pointer flex-col"
          (click)="updateStatus(i)">
          <span class="result_number"> {{ item.total }} </span>
          <div class="flex items-center gap-5">
            <div
              *ngIf="item.color"
              class="h-3 w-3 min-w-[12px] rounded-full"
              [style.backgroundColor]="item.color"></div>
            <span class="name_subtitle">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeDatePicker" class="flex w-[20%] items-start justify-end">
    <div class="flex items-center justify-end gap-2">
      <div class="data_name flex">
        <p *ngIf="!startDate.value && !endDate.value">Todos</p>

        <p *ngIf="startDate.value">{{ formatedStartDate }}</p>
        <p *ngIf="endDate.value">- {{ FormatedEndDate }}</p>
      </div>
      <mat-date-range-input class="hidden" [rangePicker]="picker">
        <input class="hidden h-0 w-0" matStartDate [formControl]="startDate" />
        <input class="hidden h-0 w-0" matEndDate [formControl]="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker
        #picker
        [color]="'primary'"></mat-date-range-picker>
      <span
        *ngIf="startDate.value"
        (click)="clearFilter()"
        class="data_name cursor-pointer"
        >Limpar datas</span
      >
    </div>
  </div>
</div>
