<div class="flex w-full justify-end pb-3">
  <div
    class="flex w-full cursor-pointer items-center justify-between rounded-md transition-all duration-150 ease-in-out hover:bg-[#fafafa] hover:opacity-80"
    (click)="toggleAcordeao()">
    <div></div>
    <span class="flex justify-start text-center">{{ title }}</span>
    <div class="flex justify-end text-end">
      <div class="mr-5 flex rounded-full p-1">
        <mat-icon
          class="icon2x"
          [ngClass]="{
            rotate_icon_open: !isExpanded,
            rotate_icon_close: isExpanded
          }">
          expand_more
        </mat-icon>
      </div>
    </div>
  </div>
</div>

<div
  class="flex h-full w-full flex-wrap items-center justify-center gap-4"
  [ngClass]="{
    collapsed: !isExpanded,
    expanded: isExpanded
  }">
  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://projectivadt.com.br/"
    [access]="['any']"
    icon="fa fa-home fa-2x"
    name="Site"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://www.linkedin.com/company/projectiva-dt"
    [access]="['any']"
    icon="fa-brands fa-linkedin-in fa-2x"
    name="Linkedin"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://www.instagram.com/projectivatecnologia/"
    [access]="['any']"
    icon="fa-brands fa-instagram fa-2x"
    name="Instagram"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://www.facebook.com/projectivatecnologia"
    [access]="['any']"
    icon="fa-brands fa-facebook-f fa-2x"
    name="Facebook"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/b/s!AlBXazWve7Q5109WBEV8m34oPOiR"
    [access]="['any']"
    icon="fa fa-plane fa-2x"
    name="P. Viagem"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5sgWMiXmuF_foc6es?e=qQQbzc"
    [access]="['any']"
    icon="fa fa-bookmark fa-2x"
    name="Portfólio"></app-quick-access>

  <app-quick-access
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5q1ZV8hBG80UKt4dW?e=F4zcZ0"
    [access]="['any']"
    icon="fa-regular fa-calendar-days fa-2x"
    name="Eventos"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Comercial'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5g11s4R-jGWuEAHbC?e=QbsBGe"
    [access]="['Comercial']"
    icon="fa fa-store fa-2x"
    name="Comercial"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Comercial'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5kS2I4FT-04xFoY3y?e=3VuyZy"
    [access]="['Comercial']"
    icon="fa-solid fa-handshake fa-2x"
    name="Propostas"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Comercial'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://accounts.rdstation.com.br/"
    [access]="['Comercial']"
    icon="fa fa-paperclip fa-2x"
    name="CRM"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Comercial'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5hlpQ1Q8kQyoAnhYN?e=wc9QsQ"
    [access]="['Comercial']"
    icon="fa fa-dollar fa-2x"
    name="Reembolsos"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['People', 'Consulting'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5gn_zs_6lkQa4FSsT?e=Q1kgnW"
    [access]="['People', 'Consulting']"
    icon="fa-solid fa-clock fa-2x"
    name="Horas"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Marketing'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5gxy2eL-gHvOU89Bw?e=QvYExt"
    [access]="['Marketing']"
    icon="fa-solid fa-bullhorn fa-2x"
    name="Marketing"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Marketing'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5hkD7FlkDi98ovdeG?e=BHYeg1"
    [access]="['Marketing']"
    icon="fa-solid fa-photo-film fa-2x"
    name="Media"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Marketing'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://trello.com/invite/b/PYTrfXvQ/ATTIc5a293de2252d65347689208b99868c55A1B6833/marketing-projectiva"
    [access]="['Marketing']"
    icon="fa-brands fa-trello fa-2x"
    name="Trello"></app-quick-access>

  <app-quick-access
    *ngIf="verifyBoxAccess(['Jurídico'])"
    class="flex h-full"
    [sector]="userSector"
    [role]="userRole"
    route="https://1drv.ms/f/s!AlBXazWve7Q5glXX3WgUfUWWqn5E?e=2AQJ7U"
    [access]="['any']"
    icon="fa-solid fa-users fa-2x"
    name="Contratos"></app-quick-access>
</div>
