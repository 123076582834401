import { Route } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { HomeComponent } from './pages/admin/home/home.component';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

import { RoleAuthGuard } from 'src/app/core/guards/roleAuth.guard';

export const AppRoutingModule: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'login',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },

  {
    path: '',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },

    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        canActivate: [RoleAuthGuard],
        data: {
          routeName: 'news',
          actions: ['read'],
          goRoute: 'ticket',
        },
        loadChildren: () =>
          import('src/app/pages/admin/home/home.module').then(
            m => m.HomeModule
          ),
      },
      {
        path: 'perfil',
        loadChildren: () =>
          import('src/app/pages/admin/perfil/perfil.module').then(
            m => m.PerfilModule
          ),
      },
      {
        path: 'administrative',
        canActivate: [RoleAuthGuard],
        data: {
          routeName: ['administrativePermission'],
        },
        loadChildren: () =>
          import(
            'src/app/pages/admin/administrative/administrative.module'
          ).then(m => m.AdministrativeModule),
      },
      {
        path: 'news',
        data: {
          routeName: ['news'],
        },
        canActivate: [RoleAuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/news/news.module').then(
            m => m.NewsModule
          ),
      },
      {
        path: 'ticket',
        data: {
          routeName: ['ticket'],
        },
        canActivate: [RoleAuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/ticket/ticket.module').then(
            m => m.TicketModule
          ),
      },
      {
        path: 'projects',
        data: {
          routeName: ['project'],
        },
        canActivate: [RoleAuthGuard],
        loadChildren: () =>
          import('src/app/pages/admin/project/project.module').then(
            m => m.ProjectModule
          ),
      },
      {
        path: 'collaborator-wall',
        data: {
          routeName: ['collaborator wall'],
        },
        canActivate: [RoleAuthGuard],
        loadChildren: () =>
          import(
            'src/app/pages/admin/collaborator-wall/collaborator-wall.module'
          ).then(m => m.CollaboratorWallModule),
      },

      { path: '**', pathMatch: 'full', redirectTo: 'home' },
    ],
  },
];
