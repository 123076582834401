export const environment = {
  production: false,
  authHost: 'https://portal-dev.projectivadt.com.br/api/v1/main',
  gateway: 'https://portal-dev.projectivadt.com.br/api/v1/gateway',
  frontGoogleClientId:
    '611250733570-lk6a1lun28n3mos0vdjhc35stldlenjf.apps.googleusercontent.com',
  backGoogleClientId:
    '257699662167-41veuaultbana3sli8148mevc1ttna70.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
