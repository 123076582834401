import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserService } from 'src/app/core/services/user/user.service';
import { TicketService } from 'src/app/core/services/ticket/ticket.service';
@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() tableData!: any[];
  @Input() tableHeader!: string[];
  @Input() customHeaders!: string[];
  @Input() actionsLabel!: string;
  @Input() desactiveDelete: boolean = false;
  @Input() desactiveUpdate: boolean = false;
  @Input() desactiveResignation: boolean = false;
  @Input() activeShowMore: boolean = false;
  @Input() enableColorStatus: boolean = false;
  @Input() showEdit: boolean = true;
  @Input() showActiveStatus: boolean = true;
  @Input() showDelete: boolean = true;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @Output() disableComponentEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() resignationEvent = new EventEmitter<any>();
  @Output() showEvent = new EventEmitter<any>();
  @Output() finishEvent = new EventEmitter<any>();
  @Output() scrolEvent = new EventEmitter<any>();
  @Output() lineEvent = new EventEmitter<any>();
  displayedColumns!: string[];

  editToolTip: string = 'Editar';
  deleteToolTip: string = 'Deletar';
  resignationToolTip: string = 'Demitir';
  ticketStatus: any;


  constructor(
    private _userService: UserService,
    private _ticketService: TicketService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  dataSource = new MatTableDataSource<any>();
  isAdmin: boolean | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.tableData;
    this._changeDetectorRef.markForCheck();
  }

  async ngOnInit() {
    await this.getTicketStatus();
  }

  ngAfterViewInit(): void {
    this.isAdmin = this._userService.userAdmin;
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  async getTicketStatus() {
    this._ticketService.getAllTicketStatus().subscribe((res: any) => {
      this.ticketStatus = res;
    });
  }

  getStatusColor(name: String): String {
    return this.ticketStatus.find((e: any) => e.name == name)?.color || '';
  }

  disableComponent(item: any) {
    this.disableComponentEvent.emit(item);
  }

  edit(item: any, event: Event) {
    event.stopPropagation();
    this.editEvent.emit(item);
  }

  delete(item: any, event: Event) {
    event.stopPropagation();
    this.deleteEvent.emit(item);
  }

  resignation(item: any, event: Event) {
    event.stopPropagation();
    this.resignationEvent.emit(item);
  }

  showMore(item: any) {
    this.showEvent.emit(item);
  }

  onScroll() {
    this.scrolEvent.emit();
  }

  disableEditButton() {
    if (this.desactiveUpdate) {
      return false;
    }
    this.editToolTip = 'Você não tem permissão para editar';
    return true;
  }

  disableDeleteButton() {
    if (this.desactiveDelete) {
      return false;
    }
    this.deleteToolTip = 'Você não tem permissão para deletar';
    return true;
  }

  disableResignationButton() {
    if (this.desactiveDelete) {
      return false;
    }
    this.resignationToolTip = 'Você não tem permissão para demitir um colaborador';
    return true;
  }


  alternativeTable(header: string): Boolean {
    if (
      header !== 'actions' &&
      header !== 'active' &&
      header !== 'featured' &&
      header !== 'email' &&
      header !== 'phone' &&
      header !== 'domain' &&
      header !== 'status' &&
      header !== 'date' &&
      header !== 'totalHours' &&
      header !== 'startTime' &&
      header !== 'endTime' &&
      header !== 'user'
    ) {
      return true;
    }
    return false;
  }

  lineClickEvent(item: any) {
    this.lineEvent.emit(item);
  }

  checkElementActiveStatus(element: any) {
    return !!element.active;
  }
}
