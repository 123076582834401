<mat-sidenav-container class="h-screen" *ngIf="layout !== 'empty'">
  <mat-sidenav class="shadow-2xl" mode="side" #sidenav [opened]="openSidenav">
    <div
      class="h-full w-24 overflow-y-auto px-2 py-4"
      [ngStyle]="{ 'background-color': colors.first }">
      <a href="http://projectivadt.com.br/" class="mb-5 flex pl-2.5">
        <img src="{{ images.icon }}" class="mr-3 h-10 sm:h-10" alt="" />
      </a>
      <ul>
        <li
          *ngFor="let side of sidebar; let i = index"
          class="my-3 flex h-16 cursor-pointer justify-center rounded">
          <a
            routerLinkActive="actived-link"
            (click)="goRote(side.link)"
            [routerLink]="side.link"
            [routerLinkActiveOptions]="{ exact: false }"
            class="menu-item second-color flex flex-1 items-center justify-center rounded-lg p-0.5 text-xs font-normal hover:text-slate-500">
            <div class="flex flex-col items-center text-center">
              <mat-icon>{{ side.icon }} </mat-icon>
              <span class="pt-2">{{ side.title }}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="flex h-screen bg-white">
    <nav class="flex h-20 w-full border-b border-gray-200 bg-white py-5">
      <button
        mat-icon-button
        [ngStyle]="{ color: colors.first }"
        (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <a class="mx-auto flex w-40" href="http://projectivadt.com.br/"
        ><img src="{{ images.baner }}" alt=""
      /></a>

      <div class="flex align-middle">
        <div class="mr-1 flex flex-col justify-center">
          <mat-icon>notifications_outline</mat-icon>
        </div>
        <div class="mx-3 flex">
          <div
           (click)="redirect('perfil')"
            class="border-3 mx-1 flex h-10 w-10 items-center justify-center rounded-full border-black cursor-pointer">
            <img
              *ngIf="user.avatar"
              src="{{ user.avatar }}"
              class="mr-4 h-10 w-10 rounded-full"
              alt="" />
            <div
              *ngIf="!user.avatar"
              class="flex h-10 w-10 items-center justify-center rounded-full bg-blue-200 text-2xl">
              {{ user.firstName.charAt(0).toUpperCase() }}
            </div>
          </div>
          <div>
            <p class="user-name">{{ user.firstName }}</p>
            <p class="user-role">
              {{ user.userrole.name }}
            </p>
          </div>
        </div>

        <div class="relative flex flex-col justify-center">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Toggle dropdown">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item>Perfil</button> -->
            <button mat-menu-item (click)="redirect('perfil')">Perfil</button>
            <button mat-menu-item (click)="redirect('logout')">Sair</button>
          </mat-menu>
        </div>
      </div>
    </nav>

    <div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="layout === 'empty'">
  <router-outlet></router-outlet>
</div>
