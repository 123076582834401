import { Component, Input, OnInit } from '@angular/core';
import { ProjecService } from 'src/app/core/services/projects/projec.service';
import { ProjectSprintService } from 'src/app/core/services/projects/project-sprint.service';
import { TimeRegisterService } from 'src/app/core/services/projects/time-register.service';
import { GetProjectModel } from 'src/app/core/models/project/project.model';
import { GetProjectSprintsModel } from 'src/app/core/models/project/projectSprints.model';
import {
  GetSprintTimeRegisterModel,
  getUserTimeRegister,
} from 'src/app/core/models/project/SprintTimeRegister.model';
import { GetCollaborator } from 'src/app/core/models/auth/user.model';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/shared/notification.service';
import { CollaboratorService } from 'src/app/core/services/collaborator/collaborator.service';
import { Collaborator } from 'src/app/core/models/collaborator/collaborator.model';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-export-projects',
  templateUrl: './export-projects.component.html',
  styleUrls: ['./export-projects.component.scss'],
})
export class ExportProjectsComponent implements OnInit {
  @Input() enableProjectSelect = false;
  @Input() enableSprintSelect = false;
  @Input() enableUserSelect = false;

  projects: GetProjectModel[] = [];
  projectSprints: GetProjectSprintsModel[] = [];
  sprintTimeRegisters: GetSprintTimeRegisterModel[] = [];
  collaborators: GetCollaborator[] = [];
  allCollaborators: Collaborator[] = [];
  initMode: string = 'projects';

  selectedProject?: GetProjectModel;
  selectedProjectSprint?: GetProjectSprintsModel;
  selectedCollaborator?: any;

  timeInit: any;
  timeEnd: any;
  formatedStartDate: any = '';
  FormatedEndDate: any = '';
  selectType: any;
  selectedUser!: Collaborator;
  userTimeRegisters!: getUserTimeRegister;
  timeRegisterByUser: Subscription | undefined;

  filter: any = [];

  constructor(
    private _projectService: ProjecService,
    private _projectSprintService: ProjectSprintService,
    private _timeRegisterService: TimeRegisterService,
    private _notificationService: NotificationService,
    private _userService: CollaboratorService,
    private datePipe: DatePipe,
    public matDialogRef: MatDialogRef<any>
  ) {}

  ngOnInit() {
    this.getAllProjects();
    this.getAllCollaborators();
    this.getTimeRegistersByuser();
  }

  changeDate() {
    if (this.timeInit) {
      this.formatedStartDate = this.FormatDate(this.timeInit);
    }
    if (this.timeEnd) {
      this.FormatedEndDate = this.FormatDate(this.timeEnd);
    }
    if (this.timeInit) {
      this.filter.push({
        field: 'dateStart',
        filter: this.timeInit,
      });
    }
    if (this.timeEnd) {
      this.filter.push({
        field: 'dateEnd',
        filter: this.timeEnd,
      });
    }
    this.getUserTimeRegisters();
  }

  clearFilter() {
    this.timeInit = undefined;
    this.timeEnd = undefined;
    this.formatedStartDate = '';
    this.FormatedEndDate = '';
    this.filter = [];
    this.getUserTimeRegisters();
  }

  getAllProjects() {
    this._projectService.getProject().subscribe(projects => {
      this.projects = projects;
      this.projects.sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
    });
  }

  getProjectSprints(projectId: number) {
    this._projectSprintService
      .getProjectSprintsByProjectId(projectId)
      .subscribe(projectSprints => {
        this.projectSprints = projectSprints;
      });
  }

  getSprintTimeRegisters(sprintId: number) {
    this._timeRegisterService
      .getSprintTimeRegisterBySprintId(sprintId)
      .subscribe(sprintTimeRegisters => {
        this.sprintTimeRegisters = sprintTimeRegisters;
      });
  }

  selectProject(event: any) {
    this.selectedProject = event;
    this.collaborators = [];
    this.selectedProjectSprint = undefined;
    this.selectedCollaborator = undefined;
    this.getProjectSprints(event.id);
    this.selectType = 'project';
    this._timeRegisterService.getProjecttimeRegisterlist(event.id).subscribe();
  }

  async selectSprint(event: any) {
    this.selectedProjectSprint = event;
    this.collaborators = [];
    this.selectedCollaborator = undefined;
    this.collaborators = event.projects?.users ? event.projects?.users : [];
    this.selectType = 'sprint';
    this.collaborators = await this.getCollaborators(this.collaborators);
    this.collaborators.sort((a, b) => a.firstName.localeCompare(b.firstName));
  }

  async getCollaborators(project: any[]) {
    return project
      .filter(proj => proj.user)
      .map(proj => ({
        ...proj.user,
        fullName: `${proj.user.firstName} ${proj.user.lastName}`
      }));
  }

  async getAllCollaborators() {
    this._userService.getAllCollaborator().subscribe((res: Collaborator[]) => {
      this.allCollaborators = res;
    });
  }

  getTimeRegistersByuser() {
    this.timeRegisterByUser =
      this._timeRegisterService.userTimeRegisters$.subscribe(
        (data: getUserTimeRegister) => {
          if (data) {
            this.userTimeRegisters = data;
          }
        }
      );
  }

  async getUserTimeRegisters() {
    if (this.selectedCollaborator) {
      this._timeRegisterService
        .getUserTimeRegisters(this.selectedCollaborator.id, this.filter)
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.timeRegisterByUser?.unsubscribe();
  }

  selectCollaborator(event: any) {
    this.selectedCollaborator = event;
    this.selectType = 'collaborator';
    this.getUserTimeRegisters();
  }

  changeMode(mode: string) {
    this.initMode = mode;
    this.selectedProject = undefined;
    this.selectedProjectSprint = undefined;
    this.selectedCollaborator = undefined;
    this.timeInit = undefined;
    this.timeEnd = undefined;
    this.formatedStartDate = '';
    this.FormatedEndDate = '';
    this.collaborators = [];
  }

  extractExcell() {
    if (this.initMode === 'projects') {
      if (!this.selectedProject) {
        this._notificationService.showNotification(
          'Por favor, selecione um projeto!',
          'OK',
          5000
        );
      }
      if (this.selectedProject) {
        const projectName: any = this.selectedProject?.name;
        if (!this.selectedProjectSprint) {
          this._timeRegisterService.downloadAllProjectsExcel(
            projectName,
            this.selectedProject.id!.toString()
          );
        } else if (this.selectedProjectSprint && !this.selectedCollaborator) {
          const sprintName: any = this.selectedProjectSprint?.name;
          this._timeRegisterService.downloadListExcel(
            this.selectedProjectSprint.id!.toString(),
            projectName + ' - ' + sprintName
          );
        } else {
          const sprintName: any = this.selectedProjectSprint?.name;
          const collaboratorName: any = this.selectedCollaborator?.fullName;
          this._timeRegisterService.downloadListUserExcel(
            this.selectedProjectSprint.id!.toString(),
            this.selectedCollaborator.id!.toString(),
            projectName + ' - ' + sprintName + ' - ' + collaboratorName
          );
        }
      }
    }
    if (this.initMode === 'collaborators') {
      if (!this.selectedCollaborator) {
        this._notificationService.showNotification(
          'Por favor, selecione um colaborador!',
          'OK',
          5000
        );
      } else {
        const collaboratorName: any = this.selectedCollaborator?.fullName;

        this._timeRegisterService.downloadUserDateExcel(
          this.selectedCollaborator.id!.toString(),
          collaboratorName,
          this.filter
        );
      }
    }
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }

  FormatDate(date: string) {
    return this.datePipe.transform(date, 'dd/MM');
  }
}
