<div
  class="flex max-h-[900px] w-[500px] flex-col overflow-hidden py-8 md:w-[800px]">
  <!-- Header -->
  <div class="flex w-full flex-col">
    <div class="flex pb-5 pl-8 pt-2">
      <span class="text-xl font-bold text-[#535363]">Emitir relatório</span>
    </div>
    <div class="button-list">
      <button
        [ngClass]="initMode === 'projects' ? 'active' : ''"
        (click)="changeMode('projects')"
        class="select-button">
        Projetos
      </button>
      <button
        [ngClass]="initMode === 'collaborators' ? 'active' : ''"
        (click)="changeMode('collaborators')"
        class="select-button">
        Colaborador
      </button>
    </div>
  </div>

  <div *ngIf="initMode === 'projects'" class="flex h-full w-full flex-col px-8">
    <!-- Body -->
    <div class="flex w-full flex-col">
      <div class="w-full px-5 py-3">
        <div
          *ngIf="projects.length > 0"
          class="flex w-full gap-5 px-3 pb-8 pt-4">
          <div class="flex w-1/3 flex-col gap-3">
            <label for="project" class="text-sm font-semibold text-[#121A26]"
              >Projeto</label
            >
            <app-search-select
              [listDate]="projects"
              placeholder="Selecione"
              (selectItem)="selectProject($event)"
              class="w-full"
              dataFilterName="name"></app-search-select>
          </div>

          <div class="flex w-1/3 flex-col gap-3">
            <label for="project" class="text-sm font-semibold text-[#121A26]"
              >Sprint</label
            >
            <app-search-select
              [listDate]="projectSprints"
              class="w-full"
              (selectItem)="selectSprint($event)"
              placeholder="Selecione"
              dataFilterName="name"></app-search-select>
          </div>

          <div class="flex w-1/3 flex-col gap-3">
            <label for="project" class="text-sm font-semibold text-[#121A26]"
              >Colaborador</label
            >
            <app-search-select
              [listDate]="collaborators"
              class="w-full"
              (selectItem)="selectCollaborator($event)"
              placeholder="Selecione"
              dataFilterName="fullName"></app-search-select>
          </div>
        </div>
      </div>
      <div class="flex max-h-[300px] w-full">
        <app-project
          class="flex w-full"
          [typeSelect]="selectType"
          [colaboratorId]="selectedCollaborator?.id"
          [selectedSprint]="selectedProjectSprint?.id"></app-project>
      </div>

      <div class="flex min-h-[60px] w-full flex-col px-4 pt-3 text-[#4b4b5c]">
        <div class="flex py-4">
          <h2 class="text-base font-bold">Informações do relatório</h2>
        </div>
        <div class="flex w-full py-3">
          <div class="flex w-1/3 flex-col gap-3 text-sm">
            <span class="font-bold">Projeto</span>
            <span *ngIf="selectedProject" class="font-normal">{{
              selectedProject.name
            }}</span>
            <span *ngIf="!selectedProject" class="font-normal"> - </span>
          </div>

          <div class="flex w-1/3 flex-col gap-3 text-sm">
            <span class="font-bold">Sprint</span>
            <span *ngIf="selectedProjectSprint" class="font-normal">{{
              selectedProjectSprint.name
            }}</span>
            <span *ngIf="!selectedProjectSprint" class="font-normal">
              Todos
            </span>
          </div>

          <div class="flex w-1/3 flex-col gap-3 text-sm">
            <span class="font-bold">Colaborador</span>
            <span *ngIf="selectedCollaborator" class="font-normal">{{
              selectedCollaborator.fullName
            }}</span>
            <span *ngIf="!selectedCollaborator" class="font-normal">
              Todos
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="initMode === 'collaborators'"
    class="flex h-full w-full flex-col px-8">
    <div class="filters-section flex w-full gap-8 py-12">
      <div class="flex w-[40%] flex-col gap-3">
        <label for="project" class="text-sm font-semibold text-[#121A26]"
          >Colaborador</label
        >
        <app-search-select
          [listDate]="allCollaborators"
          class="w-full"
          (selectItem)="selectCollaborator($event)"
          placeholder="Selecione"
          dataFilterName="fullName"></app-search-select>
      </div>

      <div class="flex w-[40%] flex-col gap-3">
        <label for="project" class="text-sm font-semibold text-[#121A26]"
          >Período</label
        >
        <div class="data-filter flex w-full">
          <div class="data-name flex">
            <p *ngIf="!timeInit && !timeEnd">Todos</p>

            <p *ngIf="timeInit">Inicio: {{ formatedStartDate }}</p>
            <p class="pl-1" *ngIf="timeEnd">- Fim {{ FormatedEndDate }}</p>
          </div>
          <mat-date-range-input class="hidden" [rangePicker]="picker">
            <input
              class="hidden h-0 w-0"
              matStartDate
              [(ngModel)]="timeInit"
              (ngModelChange)="changeDate()" />
            <input
              class="hidden h-0 w-0"
              matEndDate
              [(ngModel)]="timeEnd"
              (ngModelChange)="changeDate()" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker
            #picker
            [color]="'primary'"></mat-date-range-picker>

          <mat-icon
            (click)="clearFilter()"
            class="icon-delete"
            matTooltip="Limpar filtro">
            delete
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="initMode === 'collaborators'" class="flex flex-1 px-16">
    <div
      class="flex w-full justify-between rounded-md bg-[#f5f5f5] p-5 text-sm font-semibold text-[#4f4f4f]"
      *ngIf="selectedCollaborator">
      <div>{{ selectedCollaborator.fullName }}</div>
      <div
        *ngIf="userTimeRegisters && userTimeRegisters.totalHours === '00:00'">
        Sem horas registradas
      </div>
      <div
        class="flex gap-1"
        *ngIf="userTimeRegisters && userTimeRegisters.totalHours !== '00:00'">
        <div>total de horas:</div>
        <div>
          {{ userTimeRegisters.totalHours }}
        </div>
      </div>
    </div>
  </div>
  <div class="mt-9 flex h-10 w-full items-center justify-center">
    <div class="flex h-full w-3/5 flex-row justify-between gap-2">
      <div
        class="flex w-1/3 cursor-pointer items-center justify-center rounded-md border border-[#3E62B3] text-center text-[#3E62B3] hover:bg-gray-100"
        (click)="closeDialog()">
        <span class="flex w-full justify-center text-center text-sm font-medium"
          >Cancelar</span
        >
      </div>

      <btn-create
        class="flex w-2/3"
        buttonText="Extrair planilha de horas"
        (buttonClick)="extractExcell()"></btn-create>
    </div>
  </div>
</div>
