<div class="flex w-96 flex-col bg-white p-5">
  <div class="mb-2 flex justify-between">
    <div class="flex items-center justify-start gap-4">
      <mat-icon *ngIf="data.danger" class="text-red-500"
        >error_outline</mat-icon
      >
      <mat-icon *ngIf="!data.danger" class="text-green-500"
        >check_circle_outline</mat-icon
      >
      <span
        class="text-lg font-bold"
        [ngClass]="data.danger ? 'text-red-500' : 'text-green-600'"
        >{{ data.title }}</span
      >
    </div>

    <mat-icon
      (click)="closeModal(false)"
      class="cursor-pointer text-xl text-slate-500"
      >close</mat-icon
    >
  </div>

  <span>{{ data.message }}</span>
  <div class="mt-3 flex">
    <button
      (click)="closeModal(false)"
      [ngClass]="
        data.danger
          ? 'border-red-500 text-red-500'
          : 'border-[#3E62B3] text-[#3E62B3]'
      "
      class="mr-1 w-full flex-auto rounded border p-3 shadow">
      {{ data.action_1 ? data.action_1 : 'Cancelar' }}
    </button>
    <button
      (click)="closeModal(true)"
      [ngClass]="data.danger ? 'bg-red-500' : 'bg-[#3E62B3]'"
      class="ml-2 w-[40%] flex-auto rounded p-3 text-white shadow">
      {{ data.action_2 ? data.action_2 : 'Ok' }}
    </button>
  </div>
</div>
