import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Birthday } from '../models/home';
import { News } from '../models/home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private host: string = environment.authHost;
  constructor(private httpClient: HttpClient) {}

  //obter todas as datas e nome do anivesariante

  getBirthday(): Observable<Birthday[]> {
    return this.httpClient.get<Birthday[]>(this.host + '/user/birthdate');
  }

  //obter todas as noticias
  getNews(): Observable<News[]> {
    return this.httpClient.get<News[]>(this.host + '/news');
  }
}
