import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface carouselImage {
  id?: number;
  user_id?: number;
  category_news_id?: number;
  title?: string;
  subtitle?: string;
  category_news?: string;
  type?: string;
  tags?: string;
  origem?: string;
  description?: string;
  source_name?: string;
  url_news?: string;
  url_external?: string;
  disabled?: number;
  featured?: boolean;
  images?: string;
  files?: string;
  url_pdf?: string;
  url_s3?: string;
  url_expire?: string;
  created_at?: string;
  updated_at?: string;
}

@Component({
  selector: 'app-carrossel',
  templateUrl: './carrossel.component.html',
  styleUrls: ['./carrossel.component.scss'],
})
export class CarrosselComponent implements OnInit {
  @Input() images: carouselImage[] = [];
  @Input() indicators = true;
  @Input() controls = true;

  selectedIndex = 0;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  //seta o index do slide
  selectImage(index: number): void {
    this.selectedIndex = index;
  }

  onPrevClick(): void {
    if (!this.images) return;

    if (this.selectedIndex === 0) {
      this.selectedIndex = this.images.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

  onNextClick(): void {
    if (!this.images) return;

    if (this.selectedIndex === this.images.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  openNews(newsItem: any) {
    this.router.navigate([`news/details/${newsItem.id}`]);
  }

  splitNews(news: any) {
    if (news) {
      const partes = news.split(',');
      return partes[0].trim();
    }
  }
}
