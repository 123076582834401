import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private host?: string;

  constructor(private _authService: AuthService) {
    this.host = environment.authHost;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Clone the request object
    let newReq = request.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      this._authService.accessToken !== undefined &&
      !AuthUtils.isTokenExpired(this._authService.accessToken)
    ) {
      newReq = request.clone({
        headers: request.headers
          .set('requestfrom', 'admin')
          .set('Authorization', 'Bearer ' + this._authService.accessToken),
      });
    }

    return next.handle(newReq);
  }
}
