<div class="list_project_container">
  <div *ngIf="typeSelect">
    <div *ngIf="showList && showList.length > 0">
      <div *ngFor="let timeList of showList" class="accordion-item">
        <div class="accordion-header" (click)="setAccordion(timeList)">
          <div *ngIf="typeSelect !== 'collaborator'" class="titles-header">
            <div>Sprint: {{ timeList.name }}</div>
            <div>{{ timeList.totalHoursRegistred }} horas</div>
          </div>
          <div
            class="header-for-colaborator"
            *ngIf="typeSelect === 'collaborator'">
            <div
              *ngIf="
                timeList.timeRegisters && timeList.timeRegisters.length === 0
              ">
              Não há registros de horas
            </div>
            <div
              class="titles-header-colaborator"
              *ngFor="let tRegister of timeList.timeRegisters">
              <div>Colaborador: {{ tRegister.name }}</div>
              <div>{{ tRegister.totalHours }} horas</div>
            </div>
          </div>
          <mat-icon
            *ngIf="
              typeSelect !== 'collaborator' &&
              timeList.totalHoursRegistred !== '00:00'
            "
            class="icon"
            [ngClass]="{ rotate: timeList.open, rotateBack: !timeList.open }"
            >expand_more</mat-icon
          >
        </div>
        <div
          *ngIf="
            typeSelect !== 'collaborator' && timeList.timeRegisters.length > 0
          "
          class="accordion-content"
          [ngClass]="{ open: timeList.open }">
          <div
            class="user-accordion"
            *ngFor="let tRegister of timeList.timeRegisters">
            <div>{{ tRegister.name }}</div>
            <div>{{ tRegister.totalHours }} horas</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
