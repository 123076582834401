/* eslint-disable prettier/prettier */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HomeService } from 'src/app/services/home.service';
import { Birthday, News, carouselImage } from '../../../models/home';
import { NewsService } from 'src/app/core/services/news/news.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { UserService } from 'src/app/core/services/user/user.service';
import { GetCollaborator } from 'src/app/core/models/auth/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  birthdays?: Birthday[];
  news?: any[];
  simpleNews?: any[];
  highlights: carouselImage[] = [];
  showMore: boolean = false;
  isClient: boolean = false;
  userRole!: String;
  userSector!: String;
  user!: GetCollaborator;

  icons = {
    birthday: 'assets/icons/icon_birthday.png',
    highlights: 'assets/icons/icon_highlights.png',
    schedule: 'assets/icons/icon_schedule.png',
    news: 'assets/icons/icon_news.png',
  };

  filters = [
    {
      name: 'Ordem',
      field: 'orderby',
      filter: 'id',
    },
    {
      name: 'order',
      field: 'order',
      filter: 'desc',
    },
    {
      name: 'Destaque',
      field: 'featured',
      filter: true,
    },
    {
      name: 'Inicio',
      field: 'startDate',
      filter: moment().format('YYYY-MM-DD'),
    },
    {
      name: 'Fim',
      field: 'lastDate',
      filter: moment().format('YYYY-MM-DD'),
    },
  ];

  simpleFilters = [
    {
      name: 'Ordem',
      field: 'orderby',
      filter: 'id',
    },
    {
      name: 'order',
      field: 'order',
      filter: 'desc',
    },
    {
      name: 'Limite',
      field: 'limit',
      filter: '7',
    },
  ];

  constructor(
    public homeService: HomeService,
    private changeDetectorRef: ChangeDetectorRef,
    private _newsService: NewsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _userservice: UserService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.loadThisUser();
    this.isClient = this._userservice.userRole === 'Cliente';
    this.userRole = this._userservice.userRole;
    if (this.isClient) {
      this.router.navigate([`ticket`]);
    }
    await this.fetchData();
    this.changeDetectorRef.detectChanges();
  }

  async loadThisUser() {
    this._userservice.user$.subscribe(user => {
      this.user = user;
      this.userSector = user.sector?.name!;
    });
  }

  async fetchData() {
    try {
      const birthday = await this.homeService.getBirthday().toPromise();
      this.birthdays = birthday;

      await this.getAllNews();

      this.sortBirthdays();
    } catch (error) {
      console.error(error);
    }
  }

  async getAllNews() {
    this._newsService.getAllNews(this.filters).subscribe(response => {
      this.news = response;

      this.createHighlights(this.news!);
      this._changeDetectorRef.markForCheck();
    });
    this._newsService.getAllNews(this.simpleFilters).subscribe(response => {
      this.simpleNews = response;
      this._changeDetectorRef.markForCheck();
    });
  }

  sortBirthdays() {
    if (this.birthdays) {
      const today = new Date();
      const currentYear = today.getFullYear();

      this.birthdays.forEach(birthday => {
        if (birthday.birthdate !== null) {
          const birthdate = new Date(
            currentYear,
            parseInt(birthday.birthdate.split('-')[1]) - 1,
            parseInt(birthday.birthdate.split('-')[2])
          );

          if (birthdate < today) {
            birthdate.setFullYear(currentYear + 1);
          }

          const timeDiff = Math.abs(birthdate.getTime() - today.getTime());
          const daysUntilBirthday = Math.ceil(timeDiff / (1000 * 3600 * 24));

          if (
            birthdate.getDate() === today.getDate() &&
            birthdate.getMonth() === today.getMonth()
          ) {
            birthday.daysUntilBirthday = 0;
          } else {
            birthday.daysUntilBirthday = daysUntilBirthday;
          }
        }
      });

      this.birthdays.sort((a, b) => a.daysUntilBirthday - b.daysUntilBirthday);
    }
  }

  getReversedNews(news: News[]): any[] {
    return news!.slice(0, 6);
  }

  formateDate(data: string): string {
    const partes = data.split('-');
    const dia = partes[2];
    const mes = partes[1];
    const ano = partes[0];

    return `${dia}/${mes}`;
  }

  public getRandomImageUrl = (width: number, height: number) => {
    const randomId = Math.floor(Math.random() * 1000);
    return `https://picsum.photos/${width}/${height}?random=${randomId}&category=agriculture`;
  };

  createHighlights(news: News[]) {
    news.map(n => {
      this.highlights!.push({
        images: n.images ? n.images : `${this.getRandomImageUrl(1920, 1080)}`,
        title: n.title,
        subtitle: n.subtitle,
        origem: n.origem,
        id: n.id,
      });
    });
  }

  openNews(id: any) {
    this.router.navigate([`news/details/${id}`]);
  }
}
