import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'; // Importe o módulo Moment

import {
  TicketModel,
  dashTicket,
  dashData,
} from 'src/app/core/models/tickets/ticket.model';

@Component({
  selector: 'app-data-container',
  templateUrl: './data-container.component.html',
  styleUrls: ['./data-container.component.scss'],
})
export class DataContainerComponent {
  @Input() data: dashData[] = [];
  @Input() activeDatePicker: boolean = false;
  @Output() dateFilter = new EventEmitter<
    { name: string; field: string; filter: any }[]
  >();
  @Output() filterEvent = new EventEmitter<number>();

  startDate = new FormControl();
  endDate = new FormControl();
  formatedStartDate: String = '';
  FormatedEndDate: String = '';

  constructor(private datePipe: DatePipe) {
    this.startDate.valueChanges.subscribe(newValue => {
      if (this.startDate.value) {
        this.formatedStartDate = this.FormatDate(newValue)!;
        this.dateFilter.emit([
          {
            name: 'Início',
            field: 'beginDate',
            filter: this.startDate.value.format('YYYY-MM-DD'),
          },
        ]);
      }
    });

    this.endDate.valueChanges.subscribe(newValue => {
      this.FormatedEndDate = this.FormatDate(newValue)!;
      if (this.endDate.value && this.startDate.value) {
        this.dateFilter.emit([
          {
            name: 'Início',
            field: 'beginDate',
            filter: this.startDate.value.format('YYYY-MM-DD'),
          },
          {
            name: 'Fim',
            field: 'endDate',
            filter: this.endDate.value.format('YYYY-MM-DD'),
          },
        ]);
      }
    });
  }
  updateStatus(value: number) {
    this.filterEvent.emit(value);
  }

  FormatDate(date: string) {
    return this.datePipe.transform(date, 'dd/MM');
  }

  clearFilter() {
    this.startDate.reset();
    this.endDate.reset();
    this.formatedStartDate = '';
    this.FormatedEndDate = '';
    this.dateFilter.emit([]);
  }
}
