<mat-select
  [placeholder]="placeholder"
  class="w-full rounded-md border border-[#CBCBCB] bg-white p-3"
  (valueChange)="select($event)"
  #singleSelect>
  <mat-option class="bg-white">
    <ngx-mat-select-search
      [formControl]="filter"
      [placeholderLabel]="placeholderLabel"
      [noEntriesFoundLabel]="nothingFound"></ngx-mat-select-search>
  </mat-option>
  <mat-option *ngFor="let data of showData" [value]="data">{{
    data[dataFilterName]
  }}</mat-option>
</mat-select>
