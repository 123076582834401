import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrosselComponent } from './carrossel/carrossel.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CreateButtonComponent } from './create-button/create-button.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { DefaultInputBsComponent } from './inputs/default-input-bs/default-input-bs.component';
import { EditInputComponent } from './inputs/edit-input/edit-input.component';
import { InputNewsComponent } from './inputs/input-news/input-news.component';
import { TagInputNewsComponent } from './inputs/tag-input-news/tag-input-news.component';
import { DataContainerComponent } from './container/data-container/data-container.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { QuickAccessComponent } from './container/quick-access/quick-access.component';
import { GreetingTimeComponent } from './container/greeting-time/greeting-time.component';
import { ListAccessComponent } from './container/list-access/list-access.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ExportProjectsComponent } from './modal/export-projects/export-projects.component';
import { SearchSelectComponent } from './inputs/select/search-select/search-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { ProjectComponent } from './listTimeRegisters/project/project.component';


@NgModule({
  declarations: [
    CarrosselComponent,
    SearchBarComponent,
    CreateButtonComponent,
    DynamicTableComponent,
    DefaultInputBsComponent,
    EditInputComponent,
    InputNewsComponent,
    TagInputNewsComponent,
    DataContainerComponent,
    QuickAccessComponent,
    GreetingTimeComponent,
    ListAccessComponent,
    ExportProjectsComponent,
    SearchSelectComponent,
    ProjectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    InfiniteScrollModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSidenavModule,
    NgxMatSelectSearchModule,
    MatSelectModule
  ],
  exports: [
    CarrosselComponent,
    SearchBarComponent,
    CreateButtonComponent,
    DynamicTableComponent,
    DefaultInputBsComponent,
    EditInputComponent,
    InputNewsComponent,
    DataContainerComponent,
    QuickAccessComponent,
    GreetingTimeComponent,
    ListAccessComponent,
    SearchSelectComponent,
    MatSlideToggleModule
  ],
})
export class SharedModule {}
