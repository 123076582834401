import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor() {}

  private sharedDataSubject: Subject<any> = new Subject<any>();

  setSharedData(data: any) {
    this.sharedDataSubject.next(data);
  }

  getSharedData() {
    return this.sharedDataSubject.asObservable();
  }
}
