import * as moment from 'moment';

export class GreetingModel {
  constructor(
    public time: TimeModel = new TimeModel(),
    public message: string = '',
    public imageUrl: string = ''
  ) {}
}

export class TimeModel {
  constructor(
    private hours: number = 0,
    private minutes: number = 0,
    private seconds: number = 0
  ) {}

  public static fromDate(date: Date): TimeModel {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return new TimeModel(hours, minutes, seconds);
  }

  public compareTo(time: TimeModel): number {
    let m1 = moment(
      `${this.hours}:${this.minutes}:${this.seconds}`,
      'hh:mm:ss'
    );
    let m2 = moment(
      `${time.hours}:${time.minutes}:${time.seconds}`,
      'hh:mm:ss'
    );

    if (m1.isSame(m2)) return 0;
    if (m1.isAfter(m2)) return 1;

    return -1;
  }
}
