import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'input-news',
  templateUrl: './input-news.component.html',
  styleUrls: ['./input-news.component.scss'],
})
export class InputNewsComponent implements OnInit {
  @Input() form!: FormGroup; // Usando '!' para dizer ao TypeScript que você cuidará da inicialização
  @Input() fieldName!: string;
  @Input() labelName!: string;

  ngOnInit(): void {
    if (!this.form) {
      throw new Error('parentForm is required!');
    }
  }
}
