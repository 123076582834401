<div
  *ngIf="verifyAccess()"
  class="flex h-full w-20 flex-col items-center justify-center text-center">
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer" />
  <div
    (click)="openWebSite()"
    class="flex h-14 w-14 cursor-pointer flex-col items-center justify-center rounded-md border bg-[#3E62B3] text-[#e9e7e7] transition-all duration-150 hover:bg-[#e9e7e7] hover:text-[#3E62B3]">
    <div class="flex">
      <i class="{{ icon }}"></i>
    </div>
  </div>

  <div class="flex w-full items-center justify-center text-center">
    <span class="flex"> {{ name }} </span>
  </div>
</div>
