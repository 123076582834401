import { Component, Input, OnInit } from '@angular/core';
import { TimeModel, GreetingModel } from './greeting-time.model';
import * as moment from 'moment';

@Component({
  selector: 'app-greeting-time',
  templateUrl: './greeting-time.component.html',
  styleUrls: ['./greeting-time.component.scss'],
})
export class GreetingTimeComponent {
  @Input() public time?: string | Date;
  @Input() public name?: string;

  private sunriseImgUrl: string = 'assets/icons/sunrise.svg';
  private sunImgUrl: string = 'assets/icons/sun.svg';
  private sunsetImgUrl: string = 'assets/icons/sunset.svg';
  private cloudyImgUrl: string = 'assets/icons/cloudy.svg';
  private moonImgUrl: string = 'assets/icons/night-moon.svg';

  public greetingList: GreetingModel[] = [
    new GreetingModel(new TimeModel(18, 0, 0), 'BOA NOITE', this.moonImgUrl),
    new GreetingModel(new TimeModel(12, 0, 0), 'BOA TARDE', this.sunriseImgUrl),
    new GreetingModel(new TimeModel(16, 0, 0), 'BOA TARDE', this.sunsetImgUrl),
    new GreetingModel(new TimeModel(7, 0, 0), 'BOM DIA', this.sunriseImgUrl),
  ];

  public thisTime = new Date().toLocaleDateString('pt-BR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  public momento = moment;
  public imgUrl!: string;
  public greetingText: string = 'BOM DIA';
  public date: Date = new Date();
  public dateText!: string;

  constructor() {
    let now = new Date();
    this.dateText = `${now.getDay()}`;
  }

  public user!: any;

  ngOnInit(): void {
    //pegar data atual e exibir ela completa em português

    {
      this.user = JSON.parse(localStorage.getItem('SocialUser') || '{}');
    }
    let date: Date;

    if (!this.time) {
      this.time = new Date();
    }

    if (typeof this.time == 'string') {
      date = new Date('1/1/1970 ' + this.time);
    } else {
      date = this.time;
    }

    let _time = TimeModel.fromDate(date);

    let greeting = this.getGreeting(_time);
    this.greetingText = greeting.message;
    this.imgUrl = greeting.imageUrl;
  }

  public getGreeting(currentTime?: TimeModel): GreetingModel {
    let now = currentTime ?? TimeModel.fromDate(new Date());

    let index = this.sortGreetingList().findIndex(
      g => g.time.compareTo(now) === 1
    );

    return index > 0
      ? this.greetingList[index - 1]
      : this.greetingList[this.greetingList.length - 1];
  }

  public sortGreetingList() {
    let ordered = this.greetingList.sort((item1, item2) =>
      item1.time.compareTo(item2.time)
    );
    return ordered;
  }
}
