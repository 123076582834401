import { Injectable } from '@angular/core';
import {
  GetProjectSprintsModel,
  ProjectSprintsModel,
} from '../../models/project/projectSprints.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectSprintService {
  private host: string = environment.authHost;

  private projectSprint!: GetProjectSprintsModel;
  private projectSprints!: GetProjectSprintsModel[];

  private _projectSprint = new BehaviorSubject<GetProjectSprintsModel>(
    this.projectSprint
  );

  private _projectSprints = new BehaviorSubject<GetProjectSprintsModel[]>([]);

  constructor(private http: HttpClient) {}

  get projectSprint$(): Observable<GetProjectSprintsModel> {
    return this._projectSprint.asObservable();
  }

  get projectSprints$(): Observable<GetProjectSprintsModel[]> {
    return this._projectSprints.asObservable();
  }

  getProjectSprints(filters?: any): Observable<GetProjectSprintsModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this.http
      .get<GetProjectSprintsModel[]>(this.host + '/project/project-sprint', {
        params: param,
      })
      .pipe(
        tap(projectSprints => {
          this._projectSprints.next(projectSprints);
        })
      );
  }

  getProjectSprintById(id: number): Observable<GetProjectSprintsModel> {
    return this.http
      .get<GetProjectSprintsModel>(this.host + '/project/project-sprint/' + id)
      .pipe(
        tap(projectSprint => {
          this._projectSprint.next(projectSprint);
        })
      );
  }

  getProjectSprintsByProjectId(
    projectId: number,
    filters?: any
  ): Observable<GetProjectSprintsModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<GetProjectSprintsModel[]>(
        this.host + '/project/project-sprint/project/' + projectId,
        { params: param }
      )
      .pipe(
        tap(projectSprints => {
          this._projectSprints.next(projectSprints);
        })
      );
  }

  createProjectSprint(
    projectSprint: ProjectSprintsModel
  ): Observable<ProjectSprintsModel> {
    return this.http
      .post<ProjectSprintsModel>(
        this.host + '/project/project-sprint',
        projectSprint
      )
      .pipe(
        tap(projectSprint => {
          this._projectSprint.next(projectSprint);
        })
      );
  }

  updateProjectSprint(
    id: number,
    projectSprint: ProjectSprintsModel
  ): Observable<ProjectSprintsModel> {
    return this.http
      .patch<ProjectSprintsModel>(
        this.host + '/project/project-sprint/' + id,
        projectSprint
      )
      .pipe(
        tap(projectSprint => {
          this._projectSprint.next(projectSprint);
        })
      );
  }

  deleteProjectSprint(id: number): Observable<ProjectSprintsModel> {
    return this.http
      .delete<ProjectSprintsModel>(this.host + '/project/project-sprint/' + id)
      .pipe(
        tap((projectSprint: ProjectSprintsModel) => {
          this._projectSprint.next(projectSprint);
          this.projectSprints = this.projectSprints.filter(p => p.id !== id);
          this._projectSprints.next(this.projectSprints);
        })
      );
  }
}
