import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GetCollaborator } from 'src/app/core/models/auth/user.model';
import { GetSectorModel } from 'src/app/core/models/sectors/sectors.model';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent implements OnInit {
  @Input() name: string = '';
  @Input() icon: string = '';
  @Input() route: string = '';
  @Input() access: string[] = [];
  @Input() role!: String;
  @Input() sector: String = '';
  @Output() dontShow = new EventEmitter<any>();
  user!: GetCollaborator;

  constructor(
    private _userservice: UserService,

    public router: Router
  ) {}

  async ngOnInit() {}

  verifyAccess(): boolean {
    if (this.access.includes('any')) {
      return true;
    }

    if (
      this.role === 'admin' ||
      this.role.toLocaleLowerCase() === 'head de inovação' ||
      this.role.toLocaleLowerCase() === 'ceo'
    ) {
      return true;
    }

    const set: any = this.sector;
    // verifica se contem setor no array de acesso
    if (this.access.includes(set)) {
      return true;
    }
    return false;
  }

  openWebSite() {
    window.open(this.route, '_blank');
  }
}
