import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
})

export class EditInputComponent implements OnInit {
  @Input() data:any;
  @Input() empty:any;
  @Input() canChange:boolean = false;
  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();
  editMode = false;
  constructor() {
  }

  ngOnInit():void {

  }

  changeMode() {
    if (this.canChange) {
      this.editMode = !this.editMode;
    }
  }

  onFocusOut() {
    this.focusOut.emit(this.data);
  }
}
