import { Component } from '@angular/core';

@Component({
  selector: 'app-tag-input-news',
  templateUrl: './tag-input-news.component.html',
  styleUrls: ['./tag-input-news.component.scss']
})
export class TagInputNewsComponent {

}
