<div
  *ngIf="!editMode"
  (click)="changeMode()"
  class="cell flex h-full w-full">
  {{ data || empty }}
</div>
<textarea
  *ngIf="editMode"
  (focusout)="onFocusOut()"
  [(ngModel)]="data"
  maxlength="200"
  class="cellInput flex h-full"
  appAutofocus
  (focusout)="editMode = false"></textarea>
