import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  RouterModule,
  Router,
  RouterLinkActive,
} from '@angular/router';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/auth/user.model';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { UserAccessService } from '../services/shared/user-access.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  cliente = [];

  activeRoute?: string;

  mode?: string;
  openSidenav?: boolean;
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);

  @ViewChild('sidenav') sidenav?: MatSidenav;
  colors = {
    first: '#F2F2F5',
    second: 'red',
  };

  sidebar = [
    {
      id: '1',
      icon: 'home_outline',
      title: 'Home',
      link: '/home/',
      services: ['open'],
    },
    {
      id: '2',
      icon: 'admin_panel_settings',
      title: 'Administrativo',
      link: '/administrative/',
      services: ['administrativePermission'],
    },
    {
      id: '3',
      icon: 'campaign',
      title: 'Novidades',
      link: '/news/',
      services: 'news',
      action: ['read'],
    },
    {
      id: '4',
      icon: 'contact_support',
      title: 'Helpdesk',
      link: '/ticket',
      services: ['ticket'],
    },
    {
      id: '5',
      icon: 'view_timeline',
      title: 'Projetos',
      link: '/projects',
      services: ['project'],
    },

    {
      id: '6',
      icon: 'badge',
      title: 'Colaboradores',
      link: '/collaborator-wall',
      services: ['collaborator wall'],
    },
  ];

  images = {
    icon: 'assets/icons/projectiva_icon.svg',
    baner: 'assets/icons/projectiva_banner.svg',
  };

  activeProjectIndex: any;

  layout: any;

  user: any;
  isAdmin?: boolean | undefined;
  isClient?: boolean | undefined;

  constructor(
    private route: ActivatedRoute,
    private _userservice: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authservice: AuthService,
    private _router: Router,
    private _layoutService: LayoutService,
    private _userAccessService: UserAccessService
  ) {}

  ngOnInit() {
    this.requestSideBarLayout();
    this.getScreenWidth().subscribe(width => {
      if (width < 640) {
        this.mode = 'over';
        this.openSidenav = false;
      } else if (width > 640) {
        this.mode = 'side';
        this.openSidenav = true;
      }
    });

    this._userservice.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this.isAdmin = this._userservice.userAdmin;
    this.isClient = this._userservice.userRole === 'Cliente';

    if (this.user.userrole.name === 'Cliente') {
      this.sidebar = [
        {
          id: '4',
          icon: 'contact_support',
          title: 'Helpdesk',
          link: '/ticket',
          services: ['ticket'],
        },
      ];
    }

    if (this.layout !== 'empty') {
      this.removeItemSidebar();
    }
  }

  async removeItemSidebar() {
    for (let i = this.sidebar.length - 1; i >= 0; i--) {
      const item = this.sidebar[i];
      let remove;
      if (item.action) {
        remove = await this._userAccessService.especificAccess(
          item.services,
          item.action
        );
      } else {
        remove = await this._userAccessService.verifyIsAccessible(
          item.services
        );
      }

      if (!remove) {
        this.sidebar.splice(i, 1);
      }
    }
  }

  requestSideBarLayout() {
    if (this.route.snapshot.data) {
      this.layout = this.route.snapshot.data['layout'];
    } else {
      this.layout = 'true';
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth$.next(event.target.innerWidth);
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  goRote(rote: string) {
    if (rote === '/administrative/') {
      this._layoutService.setSharedData({ init: true });
    }

    this._layoutService.setSharedData({ init: true });
  }

  redirect(rote: string) {
    if (rote === 'logout') {
      this._authservice.signOut();
      this._router.navigate(['login']);
      return;
    }
    this._router.navigate([rote]);
  }
}
