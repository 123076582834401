<div class="mx-5 flex h-[calc(100vh-80px)] flex-col overflow-scroll bg-white">
  <!-- Header -->
  <div class="flex h-16 w-full pt-4">
    <app-greeting-time [name]="this.user.firstName"></app-greeting-time>
  </div>

  <div
    class="flex w-full items-center border-zinc-300 pb-2 pt-6 font-bold text-slate-600">
    <div
      class="h-min-[70px] my-3 flex w-full flex-col items-center justify-center gap-3">
      <app-list-access
        [userRole]="userRole"
        [isExpanded]="true"
        class="flex w-full flex-col"
        [userSector]="userSector"></app-list-access>
    </div>
  </div>

  <div class="flex h-full w-full flex-col">
    <div class="my-4 flex flex-col lg:grid lg:grid-cols-4">
      <div class="flex flex-col lg:col-span-4">
        <div class="flex h-full flex-col">
          <div class="flex h-full w-full">
            <!-- emphasis -->
            <div
              *ngIf="news && news!.length > 0"
              class="mb-5 flex h-full w-full rounded-md bg-[#F2F2F5]">
              <div class="m-4 flex h-full w-full flex-1 flex-col">
                <div class="flex flex-row gap-5">
                  <img src="{{ icons.highlights }}" class="icons" alt="d" />
                  <mat-card-title class="custom-title"
                    >Destaques</mat-card-title
                  >
                </div>
                <div *ngIf="news" class="mb-7 flex h-full w-full flex-1">
                  <app-carrossel
                    class="flex h-full w-full flex-1"
                    [images]="highlights"></app-carrossel>
                </div>
              </div>
            </div>
          </div>

          <!-- News descomentar depois-->
          <!-- <div class="mt-8 flex h-full w-full rounded-md bg-[#F2F2F5]">

            <div class="container">
              <div class="rounded-lg bg-gray-100 p-6">
                <div class="mb-3 flex flex-row gap-3">
                  <img src="{{ icons.news }}" class="icons" alt="" />
                  <mat-card-title class="custom-title">Notícias</mat-card-title>
                  <a
                    [routerLink]="['/news']"
                    class="text-md ml-auto self-center text-sm font-semibold"
                    >Ver todos ></a
                  >
                </div>
                <mat-list class="rounded-xl bg-white">
                  <div *ngIf="news && news.length > 0; else notfoundnews">
                    <div
                      *ngFor="
                        let News of getReversedNews(news);
                        let last = last
                      "
                      class="news-item flex w-full flex-1 flex-row bg-white">
                      <div
                        class="flex h-full w-full items-center justify-center px-3 py-2">
                        <div class="card-title flex w-[60%] truncate">
                          {{ News.title }}
                        </div>

                        <div class="flex w-[40%] truncate">
                          <div class="flex flex-col">
                            <p class="card-title flex flex-col">
                              {{ News.subtitle }}
                            </p>
                            <span class="card-subtitle">
                              {{ News.createdAt | date : 'dd/MM/yyyy HH:mm' }}
                            </span>
                          </div>
                        </div>

                        <div
                          class="ml-2 flex h-full w-10 justify-center truncate py-2">
                          <mat-icon
                            class="grow-on-hover w-full cursor-pointer rounded-full bg-gray-300 text-white"
                            >add-circle</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #notfoundnews>
                    Nenhuma notícia encontrada
                  </ng-template>
                </mat-list>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="flex h-full w-full flex-col">
    <div class="my-4 flex flex-col lg:grid lg:grid-cols-4">
      <div class="flex flex-col lg:col-span-3">
        <div class="flex h-full w-full flex-1 flex-col rounded-md bg-[#F2F2F5]">
          <!-- News -->
          <div class="flex h-full w-full flex-1 flex-col">
            <div class="flex w-full flex-1 flex-col rounded-lg bg-gray-100 p-6">
              <div class="mb-3 flex w-full flex-row gap-5">
                <img src="{{ icons.news }}" class="icons" alt="" />
                <mat-card-title class="custom-title">Notícias</mat-card-title>
                <a
                  [routerLink]="['/news']"
                  class="text-md ml-auto self-center text-sm font-semibold hover:underline"
                  >Ver todos ></a
                >
              </div>
              <mat-list
                class="mat-mdc-list mat-mdc-list-base mdc-list cursor-pointer rounded-lg bg-white">
                <div
                  *ngIf="
                    simpleNews && simpleNews.length > 0;
                    else notfoundnews
                  ">
                  <div
                    *ngFor="let News of simpleNews; let last = last"
                    class="news-item flex w-full flex-1 flex-row bg-white px-2">
                    <div
                      class="flex h-full w-full flex-row items-center justify-between">
                      <div
                        (click)="openNews(News.id)"
                        class="flex h-full w-full flex-col items-center py-[9px] pl-3 lg:grid lg:grid-cols-9">
                        <div
                          class="card-title flex w-full justify-start lg:col-span-8">
                          <div class="flex w-full">
                            <div class="flex w-full flex-col">
                              <p class="card-title truncate">
                                {{ News.title }}
                              </p>
                              <span class="card-subtitle truncate">
                                <div class="truncate">{{ News.subtitle }}</div>
                                <!--{{ News.createdAt | date : 'dd/MM/yyyy HH:mm' }}-->
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="flex w-full justify-end truncate pr-2">
                          <div class="flex flex-col">
                            <p class="card-title">
                              {{ News.font || 'Projectiva' }}
                            </p>
                            <span class="card-subtitle">
                              {{ News.createdAt | date : 'dd/MM/yyyy HH:mm' }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #notfoundnews>
                  Nenhuma notícia encontrada
                </ng-template>
              </mat-list>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-5 flex h-full w-full flex-1 flex-col overflow-hidden lg:mt-0 lg:pl-4">
        <div class="rounded-md bg-gray-100 p-6 lg:mr-4">
          <div class="rounded-lg">
            <div class="mb-3 flex flex-row gap-5">
              <img src="{{ icons.birthday }}" class="icons" alt="" />
              <mat-card-title class="custom-title">Aniversários</mat-card-title>
              <!-- <a
                href="#"
                class="text-md ml-auto self-center text-sm font-semibold"
                >Ver todos ></a
              > -->
            </div>
            <mat-list class="cursor-pointer rounded-lg bg-white">
              <div
                class="mx-3"
                *ngIf="birthdays && birthdays.length > 0; else notfoudbirthday">
                <div
                  *ngFor="
                    let birthday of birthdays.slice(0, 5);
                    let last = last
                  ">
                  <div class="flex flex-1 flex-col py-4">
                    <div class="card-title mb-2 flex justify-between">
                      <span class="">
                        {{
                          birthday.firstName +
                            ' ' +
                            birthday.lastName.split(' ')[
                              birthday.lastName.split(' ').length - 1
                            ]
                        }}
                      </span>
                      <span *ngIf="birthday.birthdate">
                        {{ formateDate(birthday.birthdate) }}
                      </span>
                    </div>

                    <div class="flex flex-1" *ngIf="birthday.birthdate">
                      <span
                        *ngIf="
                          birthday?.daysUntilBirthday !== 0 &&
                            birthday.birthdate;
                          else thisDay
                        "
                        >Faltam {{ birthday?.daysUntilBirthday }} dias</span
                      >
                      <ng-template #thisDay>Hoje 🎉🎉</ng-template>
                    </div>
                  </div>
                  <mat-divider *ngIf="!last"></mat-divider>
                </div>
              </div>
              <ng-template #notfoudbirthday>
                Nenhum anivesário encontrado
              </ng-template>
            </mat-list>
          </div>
        </div>

        <!-- Descomentar quando criar a agenda -->
        <!-- <div
        class="mb-8 flex justify-center rounded-lg shadow-xl sm:mx-5 lg:mx-6">
        <div class="grid-cols-0 container grid">
          <div class="rounded-lg bg-gray-100 p-6">
            <div class="mb-3.5 flex flex-row gap-3">
              <img src="{{ icons.schedule }}" class="icons" alt="" />
              <mat-card-title class="custom-title">Agenda</mat-card-title>
              <a
                href="#"
                class="text-md ml-auto self-center text-sm font-semibold"
                >Ver todos ></a
              >
            </div>
            <mat-list class="rounded-lg bg-white">
              <mat-list-item class="my-3">
                <p class="card-title">Reunião Projectiva</p>
                <p class="card-subtitle">happy hour</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="my-3.5">
                <p class="card-title">Sprint 32</p>
                <p class="card-subtitle">Call análise</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="my-3.5">
                <p class="card-title">Reunião de Equipe</p>
                <p class="card-subtitle">Apresentação de resultados</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="my-3.5">
                <p class="card-title">Reunião de Planejamento</p>
                <p class="card-subtitle">Definição de metas trimestrais</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="my-4">
                <p class="card-title">Brainstorming</p>
                <p class="card-subtitle">
                  Geração de ideias para campanha de marketing
                </p>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</div>
