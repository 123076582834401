import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ProjectModel,
  GetProjectModel,
} from '../../models/project/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjecService {
  private host: string = environment.authHost;
  private project!: GetProjectModel;
  private projects!: GetProjectModel[];
  private _project = new BehaviorSubject<GetProjectModel>(this.project);
  private _projects = new BehaviorSubject<GetProjectModel[]>([]);

  constructor(private http: HttpClient) {}

  get project$(): Observable<GetProjectModel> {
    return this._project.asObservable();
  }

  get projects$(): Observable<GetProjectModel[]> {
    return this._projects.asObservable();
  }

  getProject(filters?: any): Observable<GetProjectModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this.http
      .get<GetProjectModel[]>(this.host + '/project/project', {
        params: param,
      })
      .pipe(
        tap(projects => {
          this._projects.next(projects);
        })
      );
  }

  getProjectById(id: number): Observable<GetProjectModel> {
    return this.http
      .get<GetProjectModel>(this.host + '/project/project/' + id)
      .pipe(
        tap(project => {
          this._project.next(project);
        })
      );
  }

  createProject(project: FormData, active: boolean): Observable<ProjectModel> {
    let projectData: any = {};
  
    project.forEach((value, key) => {
      projectData[key] = value;
    });
  
    projectData.active = active;
  
    return this.http
      .post<ProjectModel>(`${this.host}/project/project`, projectData)
      .pipe(
        tap(() => {
          this.getProject().subscribe();
        })
      );
  }

  updateProject(project: FormData, active: boolean, id: number): Observable<ProjectModel> {  
    let projectData: any = {};  

    project.forEach((value, key) => {
      projectData[key] = value;
    });
  
    projectData.active = active;
  
    return this.http
      .patch<ProjectModel>(`${this.host}/project/project/${id}`, projectData)
      .pipe(
        tap(() => {
          this.getProject().subscribe();
        })
      );
  }
  

  deleteProject(id: number): Observable<any> {
    return this.http.delete<any>(this.host + '/project/project/' + id).pipe(
      tap(() => {
        this._projects.next(this.projects.filter(project => project.id !== id));
      })
    );
  }
}
