import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, GetCollaborator } from 'src/app/core/models/auth/user.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {
  ForcedAccessModel,
  RolesModel,
  RoleAccessModel,
} from '../../models/forced-access/forced-access.model';
import roles from '../../models/forced-access/roles';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userId?: string;
  public userData?: GetCollaborator;
  public userPartner?: any;
  public userAdmin?: boolean = false;
  public userRole: String = '';
  public userAccess!: RoleAccessModel;
  public access!: any;
  public userDashboard?: boolean;
  private _user = new ReplaySubject<User>(1);
  private _users = new BehaviorSubject<User[]>([]);
  private host?: string;

  constructor(
    private _httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.host = environment.authHost;
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  set user(value: GetCollaborator) {
    // Store the value
    this.userId = value.id;
    this.userData = value;
    this.userPartner = value.partner;
    this.userRole = this.userData.userrole?.name.toLocaleLowerCase() ?? '';
    if (
      this.userRole!.toLowerCase().includes('admin') ||
      this.userRole!.toLowerCase().includes('gerente') ||
      this.userRole!.toLowerCase().includes('ceo') ||
      this.userRole!.toLowerCase().includes('head de inovação')
    ) {
      this.userAdmin = true;
    } else {
      this.userAdmin = false;
    }
    this._user.next(value);
  }

  // adcionar access ao userAccess
  getUserAccess(user: User) {
    let Access: RolesModel = {};
    let thisRole!: RoleAccessModel;
    let forcedAccess: ForcedAccessModel[] = [];
    if (user.forcedAccess) {
      forcedAccess = user.forcedAccess;
    }

    if (user.access) {
      this.access = user.access;
    }
    if (this.userRole && roles[this.userRole.toLocaleLowerCase()]) {
      Access = roles;
      thisRole = Access[this.userRole.toLocaleLowerCase()];
    }
    if (forcedAccess && thisRole) {
      for (let area in thisRole) {
        const areaString = area.toLocaleLowerCase();
        const forcedAccessArea = forcedAccess.find(
          entry => entry.function === areaString
        );
        if (forcedAccessArea) {
          thisRole[areaString] = forcedAccessArea.access;
        }
      }
      this.userAccess = thisRole;
    }
  }

  getUserById(id: string): Observable<User> {
    return this._httpClient.get<User>(this.host + '/user/id/' + id);
  }

  updateAvatar(formData: FormData, id: number): Observable<User> {
    return this._httpClient.patch<User>(
      this.host + '/user/avatar/' + id,
      formData
    );
  }

  updatePerfil(data: any, id: number): Observable<User> {
    return this._httpClient.patch<User>(`${this.host}/user/perfil/${id}`, data);
  }

  get(): Observable<User> {
    this.userId = localStorage.getItem('userId') ?? '';
    return this._httpClient
      .get<User>(this.host + '/user/id/' + this.userId)
      .pipe(
        tap(user => {
          this._user.next(user), (this.user = user);

          this.userRole =
            this.userData!.userrole?.name?.toLocaleLowerCase() ?? '';
          this.access = user.access;
          this.cookieService.set(
            'user_role',
            this.userData!.userrole?.name?.toLocaleLowerCase() ?? ''
          );

          if (user.forcedAccess) {
            this.cookieService.delete('forcedAccess');
            localStorage.removeItem('forcedAccess');
            this.cookieService.delete('access');
            this.cookieService.set(
              'forcedAccess',
              user.forcedAccess ? JSON.stringify(user.forcedAccess) : ''
            );

            localStorage.setItem(
              'forcedAccess',
              JSON.stringify(user.forcedAccess)
            );
          }
          if (this.userRole && roles[this.userRole.toLocaleLowerCase()]) {
            if (this.userRole) {
              this.cookieService.set(
                'access',
                roles[this.userRole.toLocaleLowerCase()]
                  ? JSON.stringify(roles[this.userRole.toLocaleLowerCase()])
                  : ''
              );
            }
          }

          if (this.access) {
          }

          if (
            this.userRole!.toLowerCase().includes('admin') ||
            this.userRole!.toLowerCase().includes('gerente') ||
            this.userRole!.toLowerCase().includes('ceo') ||
            this.userRole!.toLowerCase().includes('head de inovação')
          ) {
            this.userAdmin = true;
          } else {
            this.userAdmin = false;
          }

          this.getUserAccess(user);
        })
      );
  }
}
