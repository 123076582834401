<div *ngIf="images && images.length > 0" class="carousel-container">
  <img
    *ngFor="let image of images; let i = index"
    [src]="splitNews(image.images)"
    [alt]="image.title"
    class="fades flex h-full w-full object-cover"
    [ngClass]="{ 'image-active': selectedIndex === i }" />

  <div class="flex w-full" *ngFor="let image of images; let i = index">
    <a class="cursor-pointer" (click)="openNews(image)">
      <h1
        class="title-carousel z-10 h-32 overflow-hidden text-4xl font-extrabold"
        [ngClass]="{ 'title-active': selectedIndex === i }">
        {{ image.title }}
      </h1>
      <h2
        class="title-carousel sub_carrousel z-10 overflow-hidden font-semibold"
        [ngClass]="{ 'title-active': selectedIndex === i }">
        {{ image.subtitle }}
      </h2>
      <h2
        class="title-carousel date_carrousel z-10 font-semibold"
        [ngClass]="{ 'title-active': selectedIndex === i }">
        {{ image.updated_at }}
      </h2>
    </a>
  </div>

  <button
    *ngIf="controls"
    class="btn-carousel btn-prev"
    (click)="onPrevClick()">
    <span class="text-7xl opacity-50">&lsaquo;</span>
  </button>

  <button
    *ngIf="controls"
    class="btn-carousel btn-next"
    (click)="onNextClick()">
    <span class="text-7xl opacity-50">&rsaquo;</span>
  </button>

  <div *ngIf="indicators" class="carousel-dot-container">
    <span
      *ngFor="let dot of images; let i = index"
      class="dot"
      [ngClass]="{ active: selectedIndex === i }"
      (click)="selectImage(i)"></span>
  </div>
</div>
