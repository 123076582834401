<div [formGroup]="form" class="flex min-h-[70px] w-full flex-col">
  <div class="flex w-full items-center">
    <label class="label-azul">{{ labelName }}:</label>

    <div class="input-group input-group-lg flex w-full flex-row">
      <input
        class="w-full rounded-md border border-gray-300 px-2 py-3 text-base placeholder-gray-400 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500"
        [formControlName]="fieldName"
        type="text"
        (invalid)="form.get(fieldName)!.touched"
        [class.is-invalid]="
          form.get(fieldName)!.invalid && form.get(fieldName)!.touched
        "
        [name]="fieldName" />
    </div>
  </div>
  <div
    *ngIf="form.get(fieldName)!.invalid && form.get(fieldName)!.touched"
    class="slide-down-opacity text-center text-xs text-red-600">
    O campo {{ labelName }} é obrigatório.
  </div>
</div>
