import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-default-input-bs',
  templateUrl: './default-input-bs.component.html',
  styleUrls: ['./default-input-bs.component.scss'],
})
export class DefaultInputBsComponent implements OnInit {
  @Input() parentForm!: FormGroup; // Usando '!' para dizer ao TypeScript que você cuidará da inicialização
  @Input() fieldName!: string;

  ngOnInit(): void {
    if (!this.parentForm) {
      throw new Error('parentForm is required!');
    }
  }
}
