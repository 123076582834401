import { Injectable } from '@angular/core';
import {
  ForcedAccessModel,
  Access,
} from '../../models/forced-access/forced-access.model';
import { UserService } from '../user/user.service';
import roles from '../../models/forced-access/roles';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../models/auth/user.model';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  user!: User;
  constructor(
    private _userService: UserService,
    private _cookieService: CookieService
  ) {}

  async verifyIsAccessible(areas: string[]): Promise<boolean> {
    const user = await forkJoin([this._userService.get()]).toPromise();
    if (!user && !user![0]) return false;
    this.user = user![0];

    if (areas.some(area => area.includes('open'))) {
      return true;
    }

    if (areas.some(area => area.includes('administrativePermission'))) {
      return await this.isAdministrative(this.user);
    }

    let forcedAccess: ForcedAccessModel[] = [];

    const localForcedAccess = localStorage.getItem('forcedAccess');
    if (localForcedAccess) {
      forcedAccess = JSON.parse(localForcedAccess);
    } else if (this._cookieService.get('forcedAccess')) {
      forcedAccess = JSON.parse(this._cookieService.get('forcedAccess'));
    }

    let isAccessible;
    for (let area of areas) {
      isAccessible = forcedAccess.find(
        entry => entry.function === area.toLocaleLowerCase()
      );
      if (isAccessible) {
        const access = JSON.stringify(isAccessible.access).toLocaleLowerCase();
        if (access.includes('true')) {
          return true;
        }
      }
    }
    if (!isAccessible && this.user.access) {
      let userRole = this.user.access.role;
      for (let area of areas) {
        const thisArea = area.toLocaleLowerCase();
        const permissions = userRole[thisArea];
        if (permissions) {
          const permissionsString =
            JSON.stringify(permissions).toLocaleLowerCase();

          if (permissionsString.includes('true')) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async isAdministrative(user: any): Promise<boolean> {
    const access = user.access.role;

    let userRole!: string;
    if (this._cookieService.get('user_role')) {
      userRole = this._cookieService.get('user_role');
    }
    let forcedAccess: ForcedAccessModel[] = [];

    const localForcedAccess = localStorage.getItem('forcedAccess');
    if (localForcedAccess) {
      forcedAccess = JSON.parse(localForcedAccess);
    } else if (this._cookieService.get('forcedAccess')) {
      forcedAccess = JSON.parse(this._cookieService.get('forcedAccess'));
    }

    userRole = userRole.toLocaleLowerCase();
    const areas = ['partner', 'user', 'news', 'sector'];
    let count = 0;
    for (let area of areas) {
      const permissions = forcedAccess.find(
        entry => entry.function === area.toLocaleLowerCase()
      );
      if (permissions) {
        const access = permissions.access;
        if (access.create || access.update || access.delete) {
          return true;
        }
      }
    }
    if (count === areas.length) {
      return false;
    }
    for (let area of areas) {
      if (access[area].create || access[area].update || access[area].delete) {
        return true;
      }
    }

    return false;
  }

  async especificAccess(area: string, actions: string[]): Promise<boolean> {
    const user = await forkJoin([this._userService.get()]).toPromise();
    if (!user && !user![0]) return false;
    this.user = user![0];
    let userRole!: string;
    if (this._cookieService.get('user_role')) {
      userRole = this._cookieService.get('user_role');
    }
    let forcedAccess: ForcedAccessModel[] = [];
    const localForcedAccess = localStorage.getItem('forcedAccess');
    if (localForcedAccess) {
      forcedAccess = JSON.parse(localForcedAccess);
    } else if (this._cookieService.get('forcedAccess')) {
      forcedAccess = JSON.parse(this._cookieService.get('forcedAccess'));
    }
    if (forcedAccess) {
      const permissions = forcedAccess.find(
        entry => entry.function === area.toLocaleLowerCase()
      );
      if (permissions) {
        const access = permissions.access;

        for (let action of actions) {
          if (access[action]) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    const role = this.user.access.role;
    const permissions = role[area.toLocaleLowerCase()];
    if (permissions) {
      for (let action of actions) {
        if (permissions[action]) {
          return true;
        }
      }
    }
    return false;
  }

  async returnActionAccess(area: string): Promise<any> {
    const user = await forkJoin([this._userService.get()]).toPromise();
    if (!user && !user![0])
      return { create: false, update: false, delete: false };
    this.user = user![0];
    let forcedAccess: ForcedAccessModel[] = [];
    const localForcedAccess = localStorage.getItem('forcedAccess');
    if (localForcedAccess) {
      forcedAccess = JSON.parse(localForcedAccess);
    } else if (this._cookieService.get('forcedAccess')) {
      forcedAccess = JSON.parse(this._cookieService.get('forcedAccess'));
    }
    if (forcedAccess) {
      const permissions = forcedAccess.find(
        entry => entry.function === area.toLocaleLowerCase()
      );

      if (permissions) {
        return permissions.access;
      }
    }
    const role = this.user.access.role;
    const permissions = role[area.toLocaleLowerCase()];
    if (permissions) {
      return permissions;
    }
    return { read: false, create: false, update: false, delete: false };
  }

  returnAccessByArea(area: string) {
    let userAccess = this._userService.userAccess;
    if (userAccess[area.toLocaleLowerCase()]) {
      return userAccess[area.toLocaleLowerCase()];
    }
    return null;
  }
}
