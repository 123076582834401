import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, defer, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { User } from '../models/auth/user.model';
import { AuthUtils } from './auth.utils';
import { JwtParams } from '../models/auth/jwt-params.type';
import { HttpHelper } from 'src/app/modules/shared/http-helper';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  private _isAuth = new BehaviorSubject<boolean>(false);
  readonly isAuth$ = this._isAuth.asObservable();
  private readonly _authenticated: boolean = this._isAuth.getValue();

  private host: string = environment.authHost;

  constructor(
    private _userService: UserService,
    private _httpClient: HttpClient,
    private _cookieService: CookieService
  ) {
    this.host = environment.authHost;
  }

  set accessToken(token: string) {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    localStorage.setItem('accessToken', token);
    sessionStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    if (sessionStorage.getItem('accessToken')) {
      return sessionStorage.getItem('accessToken') ?? '';
    } else {
      return localStorage.getItem('accessToken') ?? '';
    }
  }

  set userId(id: string) {
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    localStorage.setItem('userId', id);
    sessionStorage.setItem('userId', id);
  }

  set refreshToken(token: string) {
    localStorage.removeItem('refreshToken');
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  signIn(credentials: { email: string; password: string }): Observable<any> {
    return this.isAuth$.pipe(
      take(1),
      switchMap(authenticated => {
        // If the user is already logged in, throws error
        if (this._authenticated) {
          return throwError(() => 'logged');
        }

        return this._httpClient
          .post<{ access_token: string }>(this.host + '/login', credentials)
          .pipe(
            tap(response => {
            }),
            catchError(HttpHelper.handleError)
          );
      }),
      tap(response => this.setUserAsAuthenticated(response.access_token))
    );
  }

  /**
   * Used to authenticate the social login token responses
   * @param token is a JWT that is decoded to type `User`
   */
  setUserAsAuthenticated(token: string): void {
    // Store the access token in the local storage
    this.accessToken = token;

    const decodedToken: User & JwtParams = jwt_decode(token);
    const { obj: user, jwt } = AuthUtils.mapTokenResponse(decodedToken);


    // Set 'userId' on localStorage and sessionStorage
    this.userId = user.id;

    // Set the authenticated flag to true
    this._isAuth.next(true);

    // Store the user on the user service
    this._userService.user = user;
  }

  // fazer um logout e redirecionar para a tela de login limpando o localstorage e o sessionstorage e cockies
  signOut(): void {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    localStorage.removeItem('forcedAccess');
    sessionStorage.removeItem('forcedAccess');
    localStorage.removeItem('refreshToken');
    this._cookieService.deleteAll();
    // Set the authenticated flag to false
    this._isAuth.next(false);
  }

  check(): Observable<boolean> {
    return this.isAuth$.pipe(
      map(authenticated => {
        // Check if the user is logged in
        if (authenticated) {
          return true;
        }
        // Check the access token availability
        if (!this.accessToken) {
          return false;
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
          return false;
        }

        return true;
      })
    );
  }
}
