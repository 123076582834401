import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  GetTicketModel,
  TicketModel,
  dashTicket,
} from 'src/app/core/models/tickets/ticket.model';
import { TicketActionModel } from '../../models/tickets/Ticket-action.model';
import { TicketFeedbackModel } from '../../models/tickets/Ticket-feedback.model';
import { TicketStatusModel } from '../../models/tickets/ticket-status.model';
import { TicketHistoryModel } from '../../models/tickets/Ticket-history.model';
import { TicketPriorityModel } from '../../models/tickets/ticket-pririty.model';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private host: string = environment.authHost;
  private refresh$ = new Subject<void>();
  private _ticket = new Subject<GetTicketModel>();
  private _tickets = new Subject<GetTicketModel[]>();
  private _dashTicket = new Subject<dashTicket>();
  private _dashTickets = new Subject<dashTicket[]>();
  private _ticketAction = new Subject<TicketActionModel>();
  private _ticketActions = new Subject<TicketActionModel[]>();
  private _ticketFeedback = new Subject<TicketFeedbackModel>();
  private _ticketFeedbacks = new Subject<TicketFeedbackModel[]>();
  private _ticketStatus = new Subject<TicketStatusModel>();
  private _ticketStatuss = new Subject<TicketStatusModel[]>();
  private _ticketHistory = new Subject<TicketHistoryModel>();
  private _ticketHistorys = new Subject<TicketHistoryModel[]>();
  private _ticketPriority = new Subject<TicketPriorityModel>();
  private _ticketPrioritys = new Subject<TicketPriorityModel[]>();

  constructor(private http: HttpClient) {}

  get ticket$(): Observable<GetTicketModel> {
    return this._ticket.asObservable();
  }

  get tickets$(): Observable<GetTicketModel[]> {
    return this._tickets.asObservable();
  }

  get dashTicket$(): Observable<dashTicket> {
    return this._dashTicket.asObservable();
  }

  get dashTickets$(): Observable<dashTicket[]> {
    return this._dashTickets.asObservable();
  }

  get ticketAction$(): Observable<TicketActionModel> {
    return this._ticketAction.asObservable();
  }

  get ticketActions$(): Observable<TicketActionModel[]> {
    return this._ticketActions.asObservable();
  }

  get ticketFeedback$(): Observable<TicketFeedbackModel> {
    return this._ticketFeedback.asObservable();
  }

  get ticketFeedbacks$(): Observable<TicketFeedbackModel[]> {
    return this._ticketFeedbacks.asObservable();
  }

  get ticketStatus$(): Observable<TicketStatusModel> {
    return this._ticketStatus.asObservable();
  }

  get ticketStatuss$(): Observable<TicketStatusModel[]> {
    return this._ticketStatuss.asObservable();
  }

  get ticketHistory$(): Observable<TicketHistoryModel> {
    return this._ticketHistory.asObservable();
  }

  get ticketHistorys$(): Observable<TicketHistoryModel[]> {
    return this._ticketHistorys.asObservable();
  }

  get ticketPriority$(): Observable<TicketPriorityModel> {
    return this._ticketPriority.asObservable();
  }

  get ticketPrioritys$(): Observable<TicketPriorityModel[]> {
    return this._ticketPrioritys.asObservable();
  }

  createTicket(data: TicketModel, form: FormData): Observable<TicketModel> {
    form.append('priority', data.priority.toString());
    form.append('action', data.action.toString());
    form.append('subject', data.subject.toString());
    form.append('sectorId', data.sectorId.toString());
    form.append('department', data.department.toString());
    form.append('status', data.status.toString());
    form.append('requesterId', data.requesterId!.toString());
    return this.http
      .post<TicketModel>(this.host + '/helpdesk/ticket', form)
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  updateTicket(
    data: TicketModel,
    form: FormData,
    id: any
  ): Observable<TicketModel> {
    form.append('priority', data.priority.toString());
    form.append('action', data.action.toString());
    form.append('subject', data.subject.toString());
    form.append('sectorId', data.sectorId.toString());
    form.append('department', data.department.toString());
    form.append('status', data.status.toString());
    if (data.images) {
      form.append('images', data.images!.toString());
    } else {
      form.append('images', data.images!);
    }
    form.append('requesterId', data.requesterId!.toString());
    return this.http
      .patch<TicketModel>(this.host + '/helpdesk/ticket/id/' + id, form)
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  updateStatus(status: any, id: Number | String): Observable<TicketModel> {
    return this.http
      .patch<TicketModel>(
        this.host + '/helpdesk/ticket/updatestatus/' + id,
        status
      )
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  patchTicket(ticket: any, id: Number | String): Observable<TicketModel> {
    return this.http
      .patch<TicketModel>(this.host + '/helpdesk/ticket/update/' + id, ticket)
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  deletId(id: string): Observable<TicketModel> {
    return this.http
      .delete<TicketModel>(this.host + '/helpdesk/ticket/id/' + id)
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  getTicketId(id: string): Observable<TicketModel> {
    return this.http
      .get<TicketModel>(this.host + '/helpdesk/ticket/id/' + id)
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  getAllTicketDash(filters?: any): Observable<dashTicket> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<dashTicket>(this.host + '/helpdesk/ticket/dash', { params: param })
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  getAllTicket(filters?: any): Observable<TicketModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketModel[]>(this.host + '/helpdesk/ticket', { params: param })
      .pipe(
        tap(() => {
          this.refresh$.next();
        })
      );
  }

  getAllTicketAction(filters?: any): Observable<TicketActionModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketActionModel[]>(this.host + '/helpdesk/ticket/ticket-action', {
        params: param,
      })
      .pipe(
        tap(tAction => {
          this._ticketActions.next(tAction);
        })
      );
  }

  getAllTicketFeedback(filters?: any): Observable<TicketFeedbackModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketFeedbackModel[]>(
        this.host + '/helpdesk/ticket/ticket-feedback',
        { params: param }
      )
      .pipe(
        tap(tFeedback => {
          this._ticketFeedbacks.next(tFeedback);
        })
      );
  }

  getAllTicketStatus(filters?: any): Observable<TicketStatusModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketStatusModel[]>(this.host + '/helpdesk/ticket/ticket-status', {
        params: param,
      })
      .pipe(
        tap(tStatus => {
          this._ticketStatuss.next(tStatus);
        })
      );
  }

  getAllTicketHistory(filters?: any): Observable<TicketHistoryModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketHistoryModel[]>(
        this.host + '/helpdesk/ticket/ticket-history',
        { params: param }
      )
      .pipe(
        tap(tHistory => {
          this._ticketHistorys.next(tHistory);
        })
      );
  }

  getAllTicketHistoryId(
    ticketid: string,
    filters?: any
  ): Observable<TicketHistoryModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this.http
      .get<TicketHistoryModel[]>(
        this.host + '/helpdesk/ticket/ticket-history/ticket/' + ticketid,
        { params: param }
      )
      .pipe(
        tap(tHistory => {
          this._ticketHistorys.next(tHistory);
        })
      );
  }

  getAllTicketPriority(filters?: any): Observable<TicketPriorityModel[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this.http
      .get<TicketPriorityModel[]>(
        this.host + '/helpdesk/ticket/ticket-priority',
        { params: param }
      )
      .pipe(
        tap(tPriority => {
          this._ticketPrioritys.next(tPriority);
        })
      );
  }
}
