import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef ,MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-feedback',
  templateUrl: './dialog-feedback.component.html',
  styleUrls: ['./dialog-feedback.component.scss']
})
export class DialogFeedbackComponent {
  constructor(
    private dialogRef: MatDialogRef<any>,
    
    @Inject(MAT_DIALOG_DATA) public data: any){}
    closeModal(result:boolean) {
      this.dialogRef.close(result);
    }
    
}
