import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  Access,
  ForcedAccessModel,
} from '../models/forced-access/forced-access.model';
import { UserService } from 'src/app/core/services/user/user.service';
import { switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UserAccessService } from '../services/shared/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class RoleAuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _userService: UserService,
    private cookieService: CookieService,
    private _userAccessService: UserAccessService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const data = route.data as any;

    return this._authService.check().pipe(
      switchMap(authenticated => {
        if (!authenticated) {
          this._router.navigate(['login'], {
            queryParams: { redirectURL: state.url },
          });
          return of(false);
        }

        if (data.actions) {
          return this._especificCheck(
            data.routeName,
            data.actions,
            data.goRoute ? data.goRoute : null
          );
        }

        return this._check(data.routeName);
      })
    );
  }

  private async _check(requiredRoles: string[]): Promise<boolean> {
    try {
      const authenticated = await this._authService.check();

      if (authenticated === undefined) {
        console.error('Erro ao verificar autenticação');
        return false;
      }

      const hasTrueAccess = await this._userAccessService.verifyIsAccessible(
        requiredRoles
      );

      if (!authenticated || !hasTrueAccess) {
        this._router.navigate(['home']);
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private async _especificCheck(
    requiredRoles: string,
    actions: string[],
    route?: string
  ): Promise<boolean> {
    try {
      const authenticated = await this._authService.check();

      if (authenticated === undefined) {
        console.error('Erro ao verificar autenticação');
        return false;
      }

      const hasTrueAccess = await this._userAccessService.especificAccess(
        requiredRoles,
        actions
      );

      if (!authenticated || !hasTrueAccess) {
        if (route) {
          this._router.navigate([route]);
          return false;
        }
        this._router.navigate(['home']);
        return false;
      }

      return true;
    } catch (error) {
      this._authService.signOut();
      console.error(error);
      return false;
    }
  }
}
