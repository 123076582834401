import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'btn-create',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss'],
})
export class CreateButtonComponent implements OnChanges {
  @Input() buttonText!: string;
  @Input() buttonIcon!: string;
  @Input() disabled: boolean = false;
  @Output() buttonClick = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }

  ngOnChanges() {
    this.disabled = this.disabled;
  }
}
