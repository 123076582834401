import { RolesModel } from './forced-access.model';

const roles: RolesModel = {
  // roles.ts
  admin: {
    news: { read: true, create: true, update: true, delete: true },
    partner: { read: true, create: true, update: true, delete: true },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  gerente: {
    news: { read: true, create: true, update: true, delete: true },
    partner: { read: true, create: true, update: true, delete: true },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },
  'full stack': {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  devops: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  front: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  back: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  ti: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  'socail media': {
    news: { read: true, create: true, update: true, delete: true },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  anuncios: {
    news: { read: true, create: true, update: true, delete: true },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  'anuncios 2': {
    news: { read: true, create: true, update: true, delete: true },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: true, create: true, update: true, delete: true },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: true, update: true, delete: true },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  'anuncios 1': {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: true, update: true, delete: true },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: false, create: true, update: false, delete: false },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: false, update: false, delete: false },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  rh: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: true, update: true, delete: true },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: false, create: true, update: false, delete: false },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: false, update: false, delete: false },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  vendedor: {
    news: { read: true, create: false, update: false, delete: false },
    partner: { read: true, create: true, update: true, delete: true },
    role: { read: true, create: true, update: true, delete: true },
    sector: { read: true, create: true, update: true, delete: true },
    ticket: { read: false, create: true, update: false, delete: false },
    update: { read: true, create: true, update: true, delete: true },
    user: { read: true, create: false, update: false, delete: false },
    'forced access': { read: true, create: true, update: true, delete: true },
    cliente: { read: true, create: true, update: true, delete: true },
  },

  cliente: {
    news: { read: false, create: false, update: false, delete: false },
    partner: { read: true, create: false, update: false, delete: false },
    role: { read: true, create: false, update: false, delete: false },
    sector: { read: true, create: false, update: false, delete: false },
    ticket: { read: true, create: true, update: true, delete: false },
    update: { read: true, create: false, update: false, delete: false },
    user: { read: true, create: false, update: false, delete: false },
    'forced access': {
      read: true,
      create: false,
      update: false,
      delete: false,
    },
    cliente: { read: true, create: false, update: false, delete: false },
  },
};

export default roles;
