<mat-form-field appearance="outline" class="h-10 w-96">
  <input
    matInput
    placeholder="Buscar"
    id="search"
    [(ngModel)]="parentSearchQuery"
    (ngModelChange)="onSearchQueryChange()" />

  <mat-icon matSuffix class="text-gray-300">search</mat-icon>
</mat-form-field>
