import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Collaborator,
  Client,
  Role,
  Sector,
  PartnerModel,
  GetPartnerModel,
  GetCollaborator,
  UserCompany,
} from 'src/app/core/models/collaborator/collaborator.model';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { tap, take, switchMap, catchError, map } from 'rxjs/operators';
import { ForcedAccessService } from '../forced-access/forced-access.service';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService {
  private roles: Role[] = [];
  private sector: Sector[] = [];
  private partner: GetPartnerModel[] = [];
  private payment: any;
  private user!: GetCollaborator;
  private collaborator: Collaborator[] = [];
  private clients: Client[] = [];
  private _role = new BehaviorSubject<Role[]>([]);
  private _sector = new BehaviorSubject<Sector[]>([]);
  private _partner = new BehaviorSubject<GetPartnerModel[]>([]);
  private _collaborators = new BehaviorSubject<Collaborator[]>([]);
  private _user = new ReplaySubject<GetCollaborator>(1);
  private _clients = new BehaviorSubject<Client[]>([]);

  private host?: string;

  constructor(
    private _httpClient: HttpClient,
    private _forcedAccessService: ForcedAccessService,
  ) {
    this.host = environment.authHost;
  }

  get roles$(): Observable<Role[]> {
    return this._role.asObservable();
  }

  get sector$(): Observable<Sector[]> {
    return this._role.asObservable();
  }

  get partner$(): Observable<GetPartnerModel[]> {
    return this._partner.asObservable();
  }

  get collaborators$(): Observable<Collaborator[]> {
    return this._collaborators.asObservable();
  }

  get clients$(): Observable<Client[]> {
    return this._clients.asObservable();
  }

  get user$(): Observable<GetCollaborator> {
    return this._user.asObservable();
  }

  getRoles(): Observable<Role[]> {
    return this._httpClient.get<Role[]>(this.host + '/role').pipe(
      tap(roles => {
        this.roles = roles;
        this._role.next(roles);
      }),
    );
  }

  getEstadosBr() {
    return this._httpClient
      .get('assets/dados/estadosbr.json')
      .pipe(map((dados: any) => dados as any[]));
  }

  getCidades(idEstado: number) {
    return this._httpClient
      .get('assets/dados/cidades.json')
      .pipe(
        map((dados: any) =>
          dados.filter((c: { Estado: number }) => c.Estado == idEstado),
        ),
      );
  }

  getAllRolesFilter(filters?: any): Observable<Role[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this._httpClient
      .get<Role[]>(this.host + '/role', { params: param })
      .pipe(
        tap(roles => {
          this.roles = roles;
          this._role.next(roles);
        }),
      );
  }

  getSectors(): Observable<Sector[]> {
    return this._httpClient.get<Sector[]>(this.host + '/sector').pipe(
      tap(sectors => {
        this.sector = sectors;
        this._sector.next(sectors);
      }),
    );
  }

  getAllSectorFilter(filters?: any): Observable<Sector[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this._httpClient
      .get<Sector[]>(this.host + '/sector', { params: param })
      .pipe(
        tap(sectors => {
          this.sector = sectors;
          this._sector.next(sectors);
        }),
      );
  }

  getPartners(): Observable<GetPartnerModel[]> {
    return this._httpClient.get<GetPartnerModel[]>(this.host + '/partner').pipe(
      tap(partner => {
        this.partner = partner;
        this._partner.next(partner);
      }),
    );
  }

  getAllCollaborator(filters?: any): Observable<Collaborator[]> {
    const params = this.buildParams(filters);
    return this.fetchCollaborators(params);
  }

  private buildParams(filters?: any): any {
    console.log('filters', filters);
    let params: { [key: string]: any } = {};
    (filters ?? [
      {
        name: 'Ordem',
        field: 'orderby',
        filter: 'firstName',
      },
      {
        name: 'order',
        field: 'order',
        filter: 'asc',
      }]).forEach((filter: any) => {
      params[filter.field] = filter.filter;
    });
    return params;
  }

  private fetchCollaborators(params: any): Observable<Collaborator[]> {
    return this._httpClient.get<Collaborator[]>(`${this.host}/user`, { params })
      .pipe(
        tap(collaborator => {
          this.collaborator = collaborator;
          this._collaborators.next(collaborator);
        }),
      );
  }

  getAllUsers(filters?: any): Observable<Collaborator[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this._httpClient
      .get<Collaborator[]>(this.host + '/user/all', { params: param })
      .pipe(
        tap(collaborator => {
          this.collaborator = collaborator;
          this._collaborators.next(collaborator);
        }),
      );
  }

  getAllClients(filters?: any): Observable<Collaborator[]> {
    let param = {};
    if (filters) {
      filters.map((f: any) => {
        let fil: any = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }
    return this._httpClient
      .get<Client[]>(this.host + '/client', { params: param })
      .pipe(
        tap(client => {
          this.clients = client;
          this._clients.next(client);
        }),
      );
  }

  getCollaboratorById(id: Number): Observable<GetCollaborator> {
    return this._httpClient
      .get<GetCollaborator>(this.host + '/user/id/' + id)
      .pipe(
        tap(user => {
          this._user.next(user), (this.user = user);
        }),
      );
  }

  updateCollaborator(data: any, id: number): Observable<Collaborator> {
    return this._httpClient
      .patch<Collaborator>(`${this.host}/user/${id}`, data)
      .pipe(
        tap(updatedCollaborator => {
          const index = this.collaborator.findIndex(c => c.id === id);
          if (index !== -1) {
            this.collaborator[index] = updatedCollaborator;
          }

          this._collaborators.next([...this.collaborator]);
          // this._forcedAccessService.deleteManyForcedAccess(id).subscribe();
        }),
        catchError(error => {
          console.error('Erro ao atualizar colaborador:', error);
          return throwError(error);
        }),
      );
  }

  deleteCollaborator(id: number): Observable<Collaborator> {
    return this._httpClient
      .delete<Collaborator>(`${this.host}/user/id/${id}`)
      .pipe(
        tap(deletedCollaborator => {
          const index = this.collaborator.findIndex(c => c.id === id);
          if (index !== -1) {
            this.collaborator.splice(index, 1);
            this._collaborators.next([...this.collaborator]);
          }
        }),
        catchError(error => {
          console.error('Erro ao excluir colaborador:', error);
          return throwError(error);
        }),
      );
  }

  createCollaborator(collaborator: any): Observable<Collaborator> {
    return this.collaborators$.pipe(
      take(1),
      switchMap(collaborators =>
        this._httpClient
          .post<Collaborator>(this.host + '/user', collaborator)
          .pipe(
            tap(newCollaborator => {
              this._collaborators.next([collaborator, ...collaborators]);
            }),
            catchError(error => {
              console.error('Erro ao criar colaborador:', error);
              return throwError(error);
            }),
          ),
      ),
    );
  }

  resignationCollaborator(id: number): Observable<Collaborator> {
    return this._httpClient
      .patch<Collaborator>(`${this.host}/user/${id}`, {
        resignationDate: new Date().toISOString().split('T')[0],
        active: false,
      })
      .pipe(
        tap(resignationCollaborator => {
          const index = this.collaborator.findIndex(c => c.id === id);
          if (index !== -1) {
            this.collaborator[index] = resignationCollaborator;
          }

          this._collaborators.next([...this.collaborator]);
        }),
        catchError(error => {
          console.error('Erro ao excluir colaborador:', error);
          return throwError(error);
        }),
      );
  }

  readmissionCollaborator(id: number): Observable<Collaborator> {
    return this._httpClient
      .patch<Collaborator>(`${this.host}/user/${id}`, {
        resignationDate: null,
        active: true,
      })
      .pipe(
        tap(readmissionCollaborator => {
          const index = this.collaborator.findIndex(c => c.id === id);
          if (index !== -1) {
            this.collaborator[index] = readmissionCollaborator;
          }

          this._collaborators.next([...this.collaborator]);
        }),
        catchError(error => {
          console.error('Erro ao excluir colaborador:', error);
          return throwError(error);
        }),
      );
  }

  createClient(collaborator: any): Observable<Client> {
    console.log('Iniciando requisição para criar cliente');
    return this._httpClient.post<Client>(`${this.host}/client`, collaborator).pipe(
      tap(newCollaborator => {
        const currentCollaborators = this._collaborators.getValue();
        this._collaborators.next([newCollaborator, ...currentCollaborators]);
      }),
      catchError(error => {
        console.error('Erro ao criar colaborador:', error);
        return throwError(() => error);
      }),
    );
  }

  updateClient(data: any, id: number): Observable<Client> {
    return this._httpClient
      .patch<Client>(`${this.host}/client/${id}`, data)
      .pipe(
        tap(updatedCollaborator => {
          const index = this.clients.findIndex(c => c.id === id);
          if (index !== -1) {
            this.clients[index] = updatedCollaborator;
          }

          this._clients.next([...this.clients]);
          // this._forcedAccessService.deleteManyForcedAccess(id).subscribe();
        }),
        catchError(error => {
          console.error('Erro ao atualizar colaborador:', error.error.message);

          return throwError(error);
        }),
      );
  }

  createUserCompany(data: UserCompany): Observable<UserCompany> {
    return this._httpClient
      .post(`${this.host}/user-company`, data)
      .pipe(
        tap(userCompany => {
          return userCompany;
        }),
        catchError(error => {
          console.error('Erro ao criar empresa do colaborador:', error);
          return throwError(error);
        }),
      );
  }

  updateUserCompany(data: UserCompany, id: number): Observable<UserCompany> {
    return this._httpClient
      .patch(`${this.host}/user-company/${id}`, data)
      .pipe(
        tap(userCompany => {
          return userCompany;
        }),
        catchError(error => {
          console.error('Erro ao atualizar empresa do colaborador:', error);
          return throwError(error);
        }),
      );
  }

  getUserCompany(id: number): Observable<UserCompany> {
    return this._httpClient
      .get(`${this.host}/user-company/${id}`)
      .pipe(
        tap(userCompany => {
          return userCompany;
        }),
        catchError(error => {
          console.error('Erro ao buscar empresa do colaborador:', error);
          return throwError(error);
        }),
      );
  }


}
