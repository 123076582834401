import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-access',
  templateUrl: './list-access.component.html',
  styleUrls: ['./list-access.component.scss'],
})
export class ListAccessComponent implements OnInit {
  @Input() userRole: any;
  @Input() userSector: any;
  @Input() isExpanded: boolean = false;
  @Input() title: string = `Link's rápidos`;

  constructor() {}

  ngOnInit(): void {}

  verifyBoxAccess(access: string[]): boolean {
    if (access.includes('any')) {
      return true;
    }

    if (
      this.userRole.toLocaleLowerCase() === 'admin' ||
      this.userRole === 'head de inovação' ||
      this.userRole === 'ceo'
    ) {
      return true;
    }

    const set: any = this.userSector;

    if (access.includes('Jurídico')) {
      if (this.userRole.toLocaleLowerCase() === 'jurídico') {
        return true;
      }
    }

    if (access.includes(set)) {
      return true;
    }

    return false;
  }

  toggleAcordeao() {
    this.isExpanded = !this.isExpanded;
  }
}
