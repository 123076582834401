import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  GetProjectTimeRegisterListModel,
  TimeRegistersModel,
} from 'src/app/core/models/project/SprintTimeRegister.model';
import { TimeRegisterService } from 'src/app/core/services/projects/time-register.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, OnChanges {
  @Input() typeSelect!: string;
  @Input() selectedSprint!: any;
  @Input() colaboratorId!: any;
  selectList: GetProjectTimeRegisterListModel[] = [];
  showList: GetProjectTimeRegisterListModel[] = [];

  constructor(private _timeRegisterService: TimeRegisterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._timeRegisterService.projectListTimeRegister$.subscribe(data => {
      if (data && data.length > 0) {
        this.selectList = data || [];
      }

      this.showList = [...this.selectList.map(item => ({ ...item }))];
      this.showList.forEach(item => {
        item.open = false;
      });
      if (this.selectedSprint) {
        console.log('list', this.showList);
        this.showList = this.showList.filter(
          (item: GetProjectTimeRegisterListModel) =>
            item.id === this.selectedSprint
        );

        if (this.colaboratorId) {
          this.showList = this.showList.map(item => {
            item.timeRegisters = item.timeRegisters.filter(
              (time: TimeRegistersModel) => time.userId === this.colaboratorId
            );
            return item;
          });
        }
      }
    });
  }

  setAccordion(project: GetProjectTimeRegisterListModel) {
    project.open = !project.open;
  }

  ngOnInit() {
    console.log(this.typeSelect);
  }
}
