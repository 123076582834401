import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent implements OnInit, OnChanges, OnDestroy {
  protected _onDestroy = new Subject<void>();
  @Input() listDate: any[] = [];
  @Input() placeholder = 'Selecione';
  @Input() placeholderLabel = 'Buscar';
  @Input() dataFilterName = '';
  @Input() nothingFound = 'Nenhum item encontrado';
  @Output() selectItem = new EventEmitter<any>();

  showData: any[] = [];

  filter = new FormControl('');

  constructor() {}

  ngOnInit() {
    this.showData = this.listDate;
    this.filter.valueChanges.subscribe(() => {
      this.filterData();
    });
  }

  ngOnChanges() {
    this.showData = this.listDate;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterData() {
    this.showData = this.listDate.filter(item => {
      return item[this.dataFilterName]
        .toLowerCase()
        .includes(this.filter.value?.toLowerCase());
    });
  }

  select(event: any) {
    this.selectItem.emit(event);
  }
}
